.settings-table-component input {
  margin: 0 0.2em;
}

.settings-table-component td,
.settings-table-component th {
  text-align: center;
}

#CycleCountThresholds.table.settings-table-component.settings th:nth-child(1),
#CycleCountThresholds.table.settings-table-component.settings th:nth-child(2),
#CycleCountThresholds.table.settings-table-component.settings td:nth-child(1),
#CycleCountThresholds.table.settings-table-component.settings td:nth-child(2) {
  text-align: left;
  width: 203px;
  max-width: 203px;
}

#BatteryHealthThresholds.table.settings-table-component.settings .rowTitle {
  width: 406px;
  max-width: 406px;
}

#BatteryHealthThresholds.table.settings-table-component.settings thead .rowTitle {
  font-weight: 600;
}

.settings-table-component.settings input[type='number'],
.settings-table-component.settings input[type='text'] {
  display: inline-block;
  text-align: center;
  width: 2.9375rem;
  border-radius: 3px;
  border: 1px solid #949494;
  color: #333;
  margin: 0 0.298rem 0 0.398rem;
}

.settings-table-component.settings input[type='number']:disabled,
.settings-table-component.settings input[type='text']:disabled {
  opacity: 0.6;
}

.settings-table-component.settings input[type='number']:focus,
.settings-table-component.settings input[type='text']:focus {
  background-color: #fff;
  border: 1px solid #07c;
}

.settings-table-component.settings td,
.settings-table-component.settings th {
  border: 0;
  padding: 0.4rem;
}

.settings-table-component.settings input[type='number']::-webkit-inner-spin-button,
.settings-table-component.settings input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.settings-table-component.settings input::placeholder {
  color: #a3a3a3;
}

.settings-table-component.settings th {
  font-weight: 600;
}

.settings-table-component.settings .rowTitle {
  font-weight: 400;
  text-align: left;
}

.settings-table-component.settings input:invalid {
  border-color: #ed6d6d;
  background-color: #ed6d6d15;
}

.settings-table-component.settings input:invalid:focus {
  background-color: #ed6d6d10;
}
