.eTable-loading {
  text-align: center;
  height: 25rem;
  line-height: 25rem;
  background-color: #fff;
}

.eTable-loading .fa-spinner {
  position: relative;
}
