.alerts-panel {
  /* Padding should match .details-panel  */
  padding: 1.8em 2.86em 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (width <= 992px) {
  .alerts-panel {
    padding: 1.8em 2.5em 2em;
  }
}

.alerts-panel__header,
.alerts-panel__alert {
  display: inherit;
  padding: 0;
}

.alerts-panel__header {
  justify-content: space-between;
  font-weight: 700;
  width: 100%;
  padding: 0 0.15rem;
}

.alerts-panel__header .alerts-panel__title {
  font-size: var(--font-size-18px);
}

.alerts-panel__header .alerts-panel__info {
  font-size: var(--font-size-15px);
}

.alerts-panel__info__time-ago {
  font-weight: 400;
}

.alerts-panel__info__icon {
  color: #4a76a0;
  margin-right: 0.2em;
}

.alerts-panel__alerts {
  max-height: 240px; /* Vertical space for 5 alerts */
  overflow-y: auto;
  padding-right: 1em;
  margin-top: 1.4em; /* Prevent first alert scrolling into title */
}

.alerts-panel__alert {
  margin: 1.4em auto 0;
  font-size: 1.143em;
}

.alerts-panel__alert:first-child {
  margin-top: 0;
}

.alerts-panel__alert-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  padding: 0.1rem;
}

.alerts-panel__alert-message {
  display: block;
  overflow: hidden;
}

.alerts_icon-message {
  display: flex;
  align-items: flex-start;
}
