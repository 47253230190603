.display-thresholds-section.warning.settings input {
  margin: 0 1em;
}

.display-thresholds-section.problem.settings input {
  margin: 0 1em;
}

.display-settings-message {
  margin: 0 0 0 1.5em;
}
