.core_device_applications_composition .inner_card {
  position: relative;
}

.core_device_applications_composition .noSelectionOverlay {
  padding: 0;
}

.core_device_applications_composition .noSelectionOverlayInner {
  height: 100%;
  background-color: #f3f7fb;
}

.core-device_application-30-day-application-usage .noSelectionOverlayInner {
  background-color: #f3f7fb;
}

.core-device_application-30-day-application-usage .chart-title {
  margin-bottom: 1rem;
  font-size: 1.143em;
}

.core-device_application-30-day-application-usage {
  margin: 1rem 0;
  flex: auto;
}

.core-device_application-30-day-application-usage .chart-header {
  display: flex;
  justify-content: space-between;
}

.core-device_application-30-day-application-usage .chart-and-legend-container {
  display: flex;
}

.core-device_application-30-day-application-usage .chart-container {
  display: inline-block;
  flex-basis: 93%;
}

.core-device_application-30-day-application-usage #application-usage-30-day-chart-legend {
  bottom: 40px;
  flex-basis: 7%;
}

#applications .table_part tr:hover {
  background: #e7f2fc !important;
  color: #1f71a8;
}

#applications tr:first-child:hover {
  color: #333;
}

.application-usage-30-day-tooltip-title {
  font-weight: 600;
}

.application-usage-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_application-30-day-application-usage .noSelectionOverlay {
  height: 100%;
  padding: 0;
}

.core-device_application-30-day-application-usage .key-blue {
  height: 10px;
  width: 10px;
  background-color: #4aa4ba;
  border-radius: 2px;
  display: inline-block;
}

@media (width <= 1300px) {
  .core-device_application-30-day-application-usage .chart-container {
    display: inline-block;
    flex-basis: 85%;
  }

  .core-device_application-30-day-application-usage #application-usage-30-day-chart-legend {
    bottom: 40px;
    flex-basis: 15%;
  }
}

@media (width <= 1000px) {
  .core-device_application-30-day-application-usage .chart-container {
    display: inline-block;
    flex-basis: 80%;
  }

  .core-device_application-30-day-application-usage #application-usage-30-day-chart-legend {
    bottom: 40px;
    flex-basis: 20%;
  }
}

.application-usage-chart {
  position: relative;
}

.application-usage-chart .application-usage-chart-close {
  z-index: 1000;
  position: relative;
  margin-right: -1.1rem;
  margin-top: -0.75rem;
  border: 1px solid #d7e2eb;
}

.application-usage-chart .application-usage-chart-close:hover {
  border-color: transparent;
}

.application-usage-chart .noSelectionOverlay {
  z-index: 999;
}
