.column-date-chart__tooltip-title {
  font-weight: 600;
}

.column-date-chart-tooltip-container .chart-key {
  height: 10px;
  width: 10px;
  display: inline-block;
  /* stylelint-disable-next-line */
  background-color: var(--colour);
}
