.role-selection {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.role-selection__row {
  display: flex;
}

.role-selection label {
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
}

.role-selection__input {
  cursor: pointer;
  margin-left: 4px;
  margin-right: 16px;
}
