.percentage-bar {
  height: 15px;
  border-radius: 50px;
  background: #d5e2ec;
  overflow: hidden;
}

.percentage-bar-filler {
  height: 100%;
}
