.threshold-settings-composition.devices,
.threshold-settings-composition.alerts,
.threshold-settings-composition.alarms {
  margin-left: 1rem;
}

.threshold-settings-composition .threshold_header i {
  position: relative;
  right: 3px;
}

.threshold-settings-composition .battery-threshold-header h6 {
  font-size: 1.072em;
  font-weight: 600;
  padding-left: 0.4rem;
  display: inline;
}

.threshold-settings-composition .battery-alerts .title-header {
  font-size: 0.938rem;
  font-weight: 600;
  padding-left: 1.3rem;
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}

.threshold-settings-composition .averageDischarge {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .time {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .distance {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .reboots {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .low-power-events {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .drops {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .usage {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .outOfContact {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .battery-threshold-header {
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.threshold-settings-composition .battery-cycle-count {
  margin-bottom: 2em;
}

.threshold-settings-composition .battery-threshold-header .textCenter {
  display: inline;
}

.threshold-settings-composition .battery-threshold-header .threshold-icon {
  font-size: 0.8rem;
  color: #8aa8c1;
  padding-left: 0.3rem;
  cursor: pointer;
}

.threshold-settings-composition .settings-group {
  margin-left: 1.25rem;
  margin-right: 3rem;
}

.settings_container .nav-tabs .nav-item .nav-link {
  background: #fff;
}

.settings_container .nav-tabs .nav-item {
  width: 100%;
  margin-left: 1rem;
}

.threshold-settings-composition input {
  display: inline-block;
  text-align: center;
  width: 2.9375rem;
  border-radius: 3px;
  border: 1px solid #949494;
  color: #333;
  margin-right: 0.298rem;
}

.threshold-settings-composition input:disabled {
  opacity: 0.6;
}

.threshold-settings-composition input:focus {
  background-color: #fff;
  border: 1px solid #07c;
}

.threshold-settings-composition input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.threshold-settings-composition input::placeholder {
  color: #a3a3a3;
}

.threshold-settings-composition input:invalid {
  border-color: #ed6d6d;
  background-color: #ed6d6d15;
}

.threshold-settings-composition input:invalid:focus {
  background-color: #ed6d6d10;
}

@media (width <= 992px) {
  .threshold-settings-composition table {
    margin-bottom: 0.5rem;
  }
}

@media (width <= 1200px) {
  .threshold-settings-composition {
    margin-right: 0;
  }
}

@media (width <= 992px) {
  .settings_container {
    margin-right: 0;
    margin-left: 0;
    max-width: 972px;
  }
}
