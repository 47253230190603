.group-tag-picker__control {
  position: relative;
  border: 1px solid #949494;
  border-radius: 3px;
  font-size: 0.875rem;
  cursor: text;
  display: inline-block;
  padding: 6px;
}

.group-tag-picker__control--is-focused {
  border-color: #07c;
  outline-style: auto;
  outline-color: #07c;
}

.group-tag-picker__multi-value {
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 3px 8px;
  border: 1px solid #438344;
  border-radius: 5px;
  font-size: inherit;
  line-height: inherit;
  background-color: #438344;
}

.group-tag-picker .group-tag-picker__indicators {
  align-self: flex-start;
  height: 27px;
}

.group-tag-picker__placeholder {
  position: absolute;
  bottom: 0;
}

.group-tag-picker__input-container:not([data-value='']) {
  background: #fff;
}

.group-tag-picker__input-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
}

.group-tag-picker__menu {
  z-index: 1001 !important;
  min-width: 9.5rem !important;
  width: unset !important;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c2d1e0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  margin-top: 0.25rem;
  overflow: hidden auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}

.group-tag-picker__option,
.group-tag-picker__menu-notice {
  padding: .1875rem .3rem;
  width: 100%;
}

.group-tag-picker__option {
  cursor: pointer !important;
}

.group-tag-picker__option--is-focused {
  background-color: #1279c9;
  color: white;
}

.group-tag-picker .group-tag-picker__multi-value {
  color: white;
  padding: 0.2rem;
}

.group-tag-picker .group-tag-picker__multi-value__label {
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
}

.group-tag-picker .group-tag-picker__multi-value__remove svg {
  cursor: pointer;
  background-color: #c03b3b;
  border-radius: 2px;
  margin: 0 0.2rem;
  padding: 1px;
  height: 1.125rem;
  width: 1.125rem;
}

.group-tag-picker .group-tag-picker__multi-value__remove:hover svg {
  background-color: #a72222;
}

.group-tag-picker__menu .autosuggest__suggestion {
  font-weight: 300;
}

.group-tag-picker__option .autosuggest__suggestion--italic {
  font-style: italic;
}
