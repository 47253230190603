.option-picker {
  display: inline-block;
}

.option-picker__control {
  --control-bg-color: #edf6ff;
  --control-fg-color: #07c;

  max-width: 12rem;
  min-width: 5rem;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding-left: 0.625rem;
  box-sizing: border-box;
  line-height: 1.75rem;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.875rem;
  white-space: nowrap;
  border: 1px solid var(--control-fg-color);
  background-color: var(--control-bg-color);
  color: var(--control-fg-color);
  font-weight: 600;
  min-height: unset !important;
  padding-right: 1.4rem;
  cursor: pointer !important;
}

.option-picker__control:hover {
  background-color: var(--control-bg-color);
}

:not(.option-picker--is-disabled) > .option-picker__control:hover,
.option-picker__control--menu-is-open {
  border: 1px solid var(--control-fg-color);

  --control-bg-color: #bbddfe;
}

.option-picker__placeholder {
  max-width: 11rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-picker__value-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0;
}

.option-picker__value-container .option-picker__input-container:not([data-value='']) {
  background: var(--control-bg-color);
}

.option-picker__control:not(.option-picker__control--menu-is-open) .option-picker__value-container--has-value .option-picker__placeholder {
  display: none;
}

.option-picker__control--menu-is-open .option-picker__value-container--has-value > .option-picker__single-value {
  display: none;
}

.option-picker__control::after {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 5px;
}

.option-picker--is-disabled {
  opacity: 0.5;
}

.option-picker--inactive > .option-picker__control {
  --control-bg-color: #f2f2f2;

  border: 1px solid var(--control-bg-color);
  color: #333;
  font-weight: 400;
}

.option-picker--inactive > .option-picker__control:hover {
  --control-bg-color: #e6f7ff;
}

.option-picker--inactive:not(.option-picker--is-disabled) > .option-picker__control:hover,
.option-picker--inactive > .option-picker__control--menu-is-open {
  --control-bg-color: #d9d9d9;

  border-color: var(--control-bg-color);
}

:not(.option-picker--inactive) > .option-picker__control {
  box-shadow: 0 3px 3px 0 rgb(105 105 105 / 25%);
}

.option-picker__control--menu-is-open::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-top: none;
}

.option-picker__indicator,
.option-picker__indicator-separator {
  display: none !important;
}

.option-picker__menu {
  z-index: 1001 !important;
  min-width: 9.5rem !important;
  width: unset !important;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c2d1e0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  margin-top: 0.25rem;
  overflow: hidden;
}

.option-picker__option,
.option-picker__menu-notice {
  padding: .1875rem .3rem;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.option-picker__option {
  cursor: pointer !important;
}

.option-picker__option--is-focused {
  background-color: #1279c9;
  color: white;
}

.option-picker__menu .autosuggest__suggestion {
  font-weight: 300;
}

.option-picker__option .autosuggest__suggestion--italic {
  font-style: italic;
}
