.delete-dialogue .modal-header {
  border-bottom: 0 none;
}

.delete-dialogue .modal-footer {
  border-top: 0 none;
  font-size: 0.875rem;
}

.delete-dialogue .modal_footer {
  width: 100%;
}

.delete-dialogue .modal-title .subheader_title {
  line-height: 1.7rem;
  font-weight: 600;
}

.delete-dialogue .modal-body .checkbox_confirmation_message {
  padding: 10px;
}

.delete-dialogue .modal-body .checkbox-with-message {
  padding-top: 20px;
}

.delete-dialogue .modal-content {
  /* Width wide enough to accommodate loading spinner and button text in different languages. */
  max-width: 640px;
  margin: 0 auto;
}

.delete-dialogue .modal-content .modal-body {
  height: auto;
}
