.edit-home-location__modal .modal-body {
  height: auto;
}

.edit-home-location__modal .modal_footer {
  width: 100%;
}

.edit-home-location__modal .modal-header .info-popover-icon {
  font-size: var(--font-size-16px);
}

.edit-home-location__header {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-semi-bold);
}

.edit-home-location__body .form-field--field-latitude .form-field__input,
.edit-home-location__body .form-field--field-longitude .form-field__input,
.edit-home-location__body .form-field--field-radius .form-field__input {
  width: 75px;
}

.edit-home-location__body .form-field--field-postalCode .form-field__input {
  width: 100px;
}

.edit-home-location__footer-message {
  white-space: nowrap;
}

.edit-home-location__footer-message::before {
  content: '*';
  display: inline-block;
  color: var(--color-red);
  margin-right: 0.25rem;
}
