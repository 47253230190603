.chip {
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1.75rem;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.875rem;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
}

.chip:disabled {
  opacity: 0.6;
  cursor: auto;
}

.chip__text {
  display: flex;
}

.chip__additional-info {
  display: flex;
}

.chip svg,
.chip i {
  align-self: center;
  margin-right: 6px;
  margin-left: 6px;
}

.chip__count-divider + svg,
.chip__count-divider + i {
  margin-left: 0;
}

.chip__count-divider {
  align-self: center;
  height: 70%;
}

.chip__count-divider::after {
  content: '';
  display: block;
  border-right: 2px solid #d7e2eb;
  height: 100%;
  margin: 0 6px;
}

.chip:hover:not(.chip-active) .chip__count-divider::after {
  border-right: 2px solid #c2d1e0;
}

.chip-active:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgb(0 119 204 / 50%);
}

.chip-active:focus-within {
  outline: none;
  box-shadow: 0 0 0 3px rgb(0 119 204 / 50%);
}

.chip:focus-within {
  outline: none;
  border-radius: 1rem;
  box-shadow: 0 0 0 3px rgb(0 119 204 / 50%);
}

.chip:hover:not(:disabled, .chip-active),
.chip-dropdown-open {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  cursor: auto;
}

.chip-active:not(.chip-dropdown) {
  cursor: auto;
}

.chip-active,
.chip-active:hover {
  border: 1px solid #07c;
  background-color: #edf6ff;
  color: #07c;
  font-weight: 600;
  box-shadow: 0 3px 3px 0 rgb(105 105 105 / 25%);
}

.chip-active svg path,
.chip-active:hover svg path {
  fill: #07c;
}

.chip-dropdown .chip-active:hover:not(:disabled),
.chip-active .chip-dropdown-open {
  border: 1px solid #07c;
  background-color: #bbddfe;  /* 10% darker */
}

.chip-dropdown {
  padding-right: 1.4rem;
}

.chip-dropdown::after {
  content: '';
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 5px;
}

.chip-dropdown-open::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid;
  border-top: none;
}
