.data-table-component {
  height: 100%;
  overflow: auto;
}

.data-table-component table {
  table-layout: fixed;
  margin-bottom: 0;
}

.data-table-component th {
  background-color: #fff;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 500;
  padding: 0.6rem;
}

.data-table-component th:nth-child(1) {
  padding-left: 2.5rem;
}

.data-table-component th:nth-last-child() {
  padding-right: 2.5rem;
}

.data-table-component .sort-up-down {
  margin-left: 0.5rem;
}

.data-table-component .sort-up-down i {
  color: #c4c4c4;
}

.data-table-component i.fas.fa-sort-down {
  transform: translateY(10%);
  margin-left: -0.62em;
}

.data-table-component .sort-up-down i.active-sort {
  color: #333;
}

.data-table-component tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.data-table-component tr:nth-child(even) {
  background-color: #fff;
}

.data-table-component tr.clickable {
  cursor: pointer;
}

.data-table-component.highlight tbody tr:hover {
  background-color: #e7f2fc !important;
}

.data-table-component thead tr::before {
  background-color: #fff;
  position: sticky;
  top: 0;
}

/* .selectedCheckbox applied when row checked, .selected - when row selected,
if row is checked and selected then .selected class will be displayed */
.data-table-component tbody tr.selectedCheckbox::before {
  background: #e7f2fc;
}

.data-table-component tbody tr.selected::before {
  background: #3fa0dc;
}

.data-table-component .selectedCheckbox td,
.data-table-component .selected td {
  background: #e7f2fc !important;
  color: #1f71a8;
}

.table-cell {
  position: relative;
  padding: 0 !important;
  overflow: visible;
  z-index: 250;
}

.table-cell[data-show-tooltip='true'] {
  z-index: 2000 !important;
}

.table-cell .table-cell_constrained {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.6rem;
}

.table-cell:nth-child(1) .table-cell_constrained {
  padding-left: 2.5rem;
}

.table-cell:nth-child(1) .table-cell_tooltip {
  left: 0;
}

.table-cell:nth-last-child() .table-cell_constrained,
.table-cell:nth-last-child() .table-cell_tooltip {
  padding-right: 2.5rem;
}

.table-cell .table-cell_tooltip {
  position: absolute;
  top: 0;
  left: -28px;
  background-color: #fff;
  padding: 0.5rem;
  min-height: 100%;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: visible;
  white-space: nowrap;
  z-index: 2000;
}

.table-cell .table-cell_copy-button {
  padding: 0 0.25rem;
  margin-left: 0;
  margin-right: 0.5rem;
}

/* scrollbar */
.data-table-component::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.data-table-component::-webkit-scrollbar-track {
  margin-top: 2.4rem;
  background: #f1f1f1;
}

.data-table-component::-webkit-scrollbar-thumb {
  background: #888;
}

.data-table-component::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.handy-scroll:not(.handy-scroll-hidden) {
  bottom: 44px;
  min-height: 17px;
  overflow: auto;
  position: fixed;
  z-index: 1000;
}
