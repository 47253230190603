/* #region WIP common selectors for charts with events */

.core-device_performance-selected-date-device-usage .chart-header,
.core-device_performance-24-hour-device-usage .chart-header,
.core-device_performance-selected-date-data-usage .chart-header,
.core-device_performance-24-hour-data-usage .chart-header,
.core-device_performance-selected-date-device-charge-level .chart-header,
.core-device_performance-24-hour-device-charge-level .chart-header,
.core-device_performance-30-day-data-usage .chart-header,
.core-device_performance-30-day-device-usage .chart-header,
.core-device_performance-30-day-average-discharge .chart-header {
  display: flex;
  justify-content: space-between;
}

.core-device_performance-selected-date-device-charge-level .chart-header-left,
.core-device_performance-24-hour-device-charge-level .chart-header-left {
  flex-basis: 26%;
  display: flex;
  justify-content: space-between;
}

.core-device_performance-selected-date-device-usage .chart-edit-events,
.core-device_performance-24-hour-device-usage .chart-edit-events,
.core-device_performance-selected-date-data-usage .chart-edit-events,
.core-device_performance-24-hour-data-usage .chart-edit-events,
.core-device_performance-selected-date-device-charge-level .chart-edit-events,
.core-device_performance-24-hour-device-charge-level .chart-edit-events,
.core-device_performance-30-day-data-usage .chart-edit-events,
.core-device_performance-30-day-device-usage .chart-edit-events,
.core-device_performance-30-day-average-discharge .chart-edit-events {
  display: flex;
}

.core-device_performance-selected-date-device-usage .chart-and-legend-container,
.core-device_performance-24-hour-device-usage .chart-and-legend-container,
.core-device_performance-selected-date-data-usage .chart-and-legend-container,
.core-device_performance-24-hour-data-usage .chart-and-legend-container,
.core-device_performance-selected-date-device-charge-level .chart-and-legend-container,
.core-device_performance-24-hour-device-charge-level .chart-and-legend-container,
.core-device_performance-30-day-data-usage .chart-and-legend-container,
.core-device_performance-30-day-device-usage .chart-and-legend-container,
.core-device_performance-30-day-average-discharge .chart-and-legend-container {
  display: inline;
}

.core-device_performance-selected-date-device-usage,
.core-device_performance-24-hour-device-usage,
.core-device_performance-selected-date-data-usage,
.core-device_performance-24-hour-data-usage,
.core-device_performance-30-day-device-usage,
.core-device_performance-30-day-data-usage,
.core-device_performance-selected-date-device-charge-level,
.core-device_performance-24-hour-device-charge-level,
.core-device_performance-30-day-average-discharge {
  position: relative;
}

.core-device_performance-selected-date-device-usage .noSelectionOverlay,
.core-device_performance-24-hour-device-usage .noSelectionOverlay,
.core-device_performance-selected-date-data-usage .noSelectionOverlay,
.core-device_performance-24-hour-data-usage .noSelectionOverlay,
.core-device_performance-30-day-device-usage .noSelectionOverlay,
.core-device_performance-selected-date-device-charge-level .noSelectionOverlay,
.core-device_performance-24-hour-device-charge-level .noSelectionOverlay,
.core-device_performance-30-day-average-discharge .noSelectionOverlay,
.core-device_performance-30-day-data-usage .noSelectionOverlay {
  padding: 0;
}

.core_device_container .card {
  overflow: visible;
}

/* #endregion */

/* #region device 24 hour usage */

.core-device_performance-24-hour-device-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.key-green {
  height: 10px;
  width: 10px;
  background-color: #0a5;
  display: inline-block;
}

.core-device_performance-24-hour-device-usage .chart-and-legend-container {
  display: inline;
}

.core-device_performance-24-hour-device-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

.device-usage-24-hour-tooltip-title {
  font-weight: 600;
}

/* #endregion */

/* #region selected date device usage */

.core-device_performance-selected-date-device-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.device-usage-selected-date-tooltip-text .key-green {
  height: 10px;
  width: 10px;
  background-color: #0a5;
  display: inline-block;
}

.core-device_performance-selected-date-device-usage .chart-and-legend-container {
  display: inline;
}

.core-device_performance-selected-date-device-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

.device-usage-selected-date-tooltip-title {
  font-weight: 600;
}

/* #endregion */

/* #region device 30 day usage */

.core-device_performance-30-day-device-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.core-device_performance-30-day-device-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

.device-usage-30-day-tooltip-text .key-green {
  height: 10px;
  width: 10px;
  background-color: #0a5;
  display: inline-block;
}

.core-device_performance-30-day-device-usage .chart-and-legend-container {
  display: inline;
}

.device-usage-30-day-tooltip-title {
  font-weight: 600;
}

/* #endregion */

/* #region data 24 hour usage */

.core-device_performance-24-hour-data-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.data-usage-24-hour-tooltip-title {
  font-weight: 600;
}

.data-usage-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_performance-selected-date-data-usage .chart-key,
.core-device_performance-30-day-data-usage .chart-key,
.core-device_performance-24-hour-data-usage .chart-key {
  height: 10px;
  width: 10px;
  display: inline-block;
}

.core-device_performance-selected-date-data-usage .chart-key--data-mobile,
.core-device_performance-30-day-data-usage .chart-key--data-mobile,
.core-device_performance-24-hour-data-usage .chart-key--data-mobile {
  background-color: #49a1a9;
}

.core-device_performance-selected-date-data-usage .chart-key--data-wifi,
.core-device_performance-30-day-data-usage .chart-key--data-wifi,
.core-device_performance-24-hour-data-usage .chart-key--data-wifi {
  background-color: #2c678c;
}

.core-device_performance-24-hour-data-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

/* #endregion */

/* #region data 30 day usage */

.core-device_performance-30-day-data-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.core-device_performance-30-day-data-usage .chart-and-legend-container {
  display: inline;
}

.data-usage-30-day-tooltip-title {
  font-weight: 600;
}

.core-device_performance-30-day-data-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

/* #endregion */

/* #region 24 hour charge level */

.core-device_performance-24-hour-device-charge-level .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.device-charge-level-24-hour-tooltip-title {
  font-weight: 600;
}

.device-charge-level-24-hour-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_performance-24-hour-device-charge-level .key {
  height: 11px;
  width: 11px;
  border: 1px solid;
  display: inline-block;
  margin-right: 0.5em;
  position: relative;
  top: 1px;
}

.core-device_performance-24-hour-device-charge-level .device-charge-level-24-hour-tooltip-text.serialnumber {
  margin-left: 1.5em;
}

.core-device_performance-24-hour-device-charge-level {
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

/* #endregion */

/* #region data selected date usage */

.core-device_performance-selected-date-data-usage .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.data-usage-selected-date-tooltip-title {
  font-weight: 600;
}

.data-usage-selected-date-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_performance-selected-date-data-usage {
  border-bottom: 3px solid #edf1f3;
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

/* #endregion */

/* #region 30 day average discharge */

.core-device_performance-30-day-average-discharge .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.average-discharge-30-day-tooltip-title {
  font-weight: 600;
}

.average-discharge-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_performance-30-day-average-discharge {
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

.core-device_performance-30-day-average-discharge .key-blue {
  height: 10px;
  width: 10px;
  background-color: #1aa0d1;
  display: inline-block;
}

/* #endregion */

/* #region selected date charge level */

.core-device_performance-selected-date-device-charge-level .chart-title {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.device-charge-level-selected-date-tooltip-title {
  font-weight: 600;
}

.device-charge-level-selected-date-tooltip-text.total {
  margin-left: 0.5em;
}

.core-device_performance-selected-date-device-charge-level .key {
  height: 11px;
  width: 11px;
  border: 1px solid;
  display: inline-block;
  margin-right: 0.5em;
  position: relative;
  top: 1px;
}

.core-device_performance-selected-date-device-charge-level .device-charge-level-24-hour-tooltip-text.serialnumber {
  margin-left: 1.5em;
}

.core-device_performance-selected-date-device-charge-level {
  margin: 1.5rem 1.875rem 0;
  padding-bottom: 1.875rem;
}

/* #endregion */

.chart-edit-events .events-shown {
  margin-right: 1em;
}

/* #region event overlays */

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject {
  width: 20px;
  height: 20px;
  overflow: visible;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='16'] .total-events-overlay-number {
  width: 20px;
  margin-left: -2px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='16'] {
  width: 21px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='9'] .total-events-overlay-number {
  width: 20px;
  margin-left: -5px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject > div {
  overflow: visible !important;
}

.total-events-overlay-number {
  background-color: #5b9ad2;
  padding: 1px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 20px;
}

.chart-container__event-overlay-icon.icon-position-lower {
  margin-top: 5px;
}

.chart-container__event-overlay-icon::before {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.chart-container__event-overlay-tooltip-header {
  font-weight: 600;
}

.chart-container__event-overlay-tooltip-text-block {
  display: flex;
  flex-direction: column;
}

.chart-container__event-overlay-tooltip-text-and-icon {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.chart-container__event-overlay-tooltip-text-and-icon .device-event-icon {
  margin-bottom: auto;
}

.chart-container__event-overlay-tooltip-text {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
}

.chart-container__event-overlay-tooltip-message {
  margin-left: 9px;
  margin-top: 3.5px;
}

.event-overlay-tooltip-separator {
  margin-top: 5px;
  border-bottom: 1.5px solid #d7e2eb;
}

.tooltip-max-events-text {
  font-weight: 600;
}

/* #endregion */
.last24HourCharts_container .chart-and-legend-container .chart-container,
.last30DaysCharts_container .chart-and-legend-container .chart-container,
.selectedDateCharts_container .chart-and-legend-container .chart-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 14rem;
}

.last24HourCharts_container .core-device_performance-24-hour-device-charge-level .chart-container,
.selectedDateCharts_container .core-device_performance-selected-date-device-charge-level .chart-container {
  z-index: 1;
  height: 14rem;
}

/* Media query for when chart legends change orientation to account for smaller viewports */

@media (width <= 818px) {
  .chart-edit-events .events-shown {
    display: none;
  }

  .selectedDateCharts_container .core-device_performance-selected-date-device-charge-level .chart-header-left,
  .last24HourCharts_container .core-device_performance-24-hour-device-charge-level .chart-header-left {
    flex-grow: 2;
  }

  .last24HourCharts_container .core-device_performance-24-hour-device-charge-level .chart-container,
  .selectedDateCharts_container .core-device_performance-selected-date-device-charge-level .chart-container {
    min-height: 300px;
  }
}
