.shared-map {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 400px;
  height: 60vh;
}

.shared-map .mapZoomButtonContainer {
  background-color: white;
  border: 2px solid rgb(0 0 0 / 30%);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  /* cannot be with other leaflet controls: https://github.com/LiveBy/react-leaflet-control/issues/16 */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.shared-map .mapResetButton {
  position: relative;
  top: calc(70px + 10px);
}

.mapZoomButton {
  padding: 9px;
}

.mapZoomButton:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.mapZoomButton:hover {
  background-color: #f4f4f4;
}

.mapZoomButton[aria-disabled="true"] {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.shared-map .noSelectionOverlay {
  padding: 0;
  border-radius: 0;
  z-index: 1000;
}

.shared-map .noSelectionOverlay .noSelectionOverlayInner {
  border-radius: 0;
}

.shared-map .leaflet-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* DROPDOWN */

.shared-map .leaflet-control:first-child {
  z-index: 1000;
}

.shared-map .dropdown {
  background: transparent;
}

.shared-map .dropdown-toggle {
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid rgb(0 0 0 / 30%);
  background-color: #fff;
  color: black;
}

.shared-map .dropdown-toggle.btn:hover:not(:disabled),
.shared-map .dropdown.show>.btn.btn-primary.dropdown-toggle,
.shared-map .dropdown-toggle.btn.btn-primary:focus {
  background-color: #f4f4f4;
  color: black;
  border-color: rgb(0 0 0 / 30%);
}

.shared-map .dropdown-menu {
  border-radius: 4px;
  border: 2px solid rgb(0 0 0 / 30%);
  background-color: #fff;
  color: black;
  padding: 0.5rem 0;
}

.shared-map .dropdown-menu:hover {
  border: 2px solid rgb(0 0 0 / 30%);
}

.shared-map .dropdown-menu .dropdown-item {
  margin-bottom: 0;
}

.shared-map .dropdown-item.active,
.shared-map .dropdown-item:active {
  background-color: #f4f4f4;
  color: #000;
}

.shared-map .dropdown-item .checkbox {
  margin-right: 0.25rem;
}

/* GRAYSCALE */

.shared-map[data-grayscale='true'] .leaflet-tile-pane {
  filter: grayscale();
}

/* TOOLTIP */

.shared-map .leaflet-tooltip {
  padding: 0.5rem;
}

.shared-map .leaflet-tooltip p {
  margin: 0.5rem 0;
}

.shared-map .leaflet-tooltip p:first-child {
  margin-top: 0;
}

.shared-map .leaflet-tooltip p:last-child {
  margin-bottom: 0;
}

/* LEGEND */

.shared-map .legend {
  padding: 0.5rem;
  margin: 0;
  border-radius: 4px;
  border: 2px solid rgb(0 0 0 / 30%);
  background-color: #fff;
}

.shared-map .legend.continuous {
  display: flex;
  flex-direction: row;
}

.shared-map .legend.continuous .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shared-map .legend.continuous .lines {
  background-color: #cbcbcb;
  padding: 4px;
  border: 1px solid black;
  border-right: none;
  margin-right: 0.5rem;
  width: calc(0.75rem + 1px * 2 + 4px * 2);
  height: 200px;
}

.shared-map .legend.continuous .gradient {
  height: 100%;
  width: 0.75rem;
  background: linear-gradient(180deg, var(--legend-stop-1) 0%, var(--legend-stop-2) 30%, var(--legend-stop-3) 80%, var(--legend-stop-4) 100%);
  border: 1px solid rgb(0 0 0 / 50%);
}

.shared-map .legend.discrete>li {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.shared-map .legend.discrete>li>.background {
  background-color: #cbcbcb;
  padding: 0.25rem;
  padding-bottom: 0;
  margin-right: 0.5rem;
}

.shared-map .legend.discrete>li:last-child>.background {
  padding-bottom: 0.25rem;
}

.shared-map .legend.discrete>li>.background>.color {
  height: 1rem;
  width: 2rem;
  border: 1px solid rgb(0 0 0 / 50%);
}

.shared-map .legend.discrete>li>.text {
  padding-top: 0.25rem;
}

/* CONTROLS */

.shared-map .map-config-button,
.shared-map .loading-indicator {
  position: relative;
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  padding: 0.5rem;
  border: 2px solid rgb(0 0 0 / 30%);
  border-radius: 0.25rem;
  font-weight: bold;
}

.shared-map .loading-indicator>i {
  margin-right: 0.5rem;
}
