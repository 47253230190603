.role-title-tooltip__text {
  width: 500px;
  line-height: 27px;
}

.role-title-tooltip__viewer,
.role-title-tooltip__editor,
.role-title-tooltip__manager,
.role-title-tooltip__administrator {
  font-weight: 600;
}

.role-title-tooltip__viewer > span,
.role-title-tooltip__editor > span,
.role-title-tooltip__manager > span,
.role-title-tooltip__administrator > span {
  font-weight: 400;
}
