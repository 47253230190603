
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.cluster-map-container {
  display: flex;
  flex-direction: row;
  padding: 1.8em 2.5rem;
  padding-top: 0;
  gap: 1rem;

  .alerts-panel {
    padding: 0;

    .alerts-panel__alerts {
      margin-top: 1px;
      max-height: none;
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 0.75rem;
      text-align: left;
      }
    
  }


  .devices-card {
    background-color: #f3f7fb;
    width: 320px;
    flex-shrink: 0;
    z-index: 1000;
  
    &.selected {
      height: auto;
    }
  
    .card-body {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .devices-card_prompt {
      margin: .5rem;
    }
  
    .devices-card_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: calc(32px + 1rem + 1px);
      padding: .5rem;
      border-bottom: 1px solid #C2D1E0;
  
      h3 {
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0;
      }

      .devices-card_header-buttons {
        display: flex;
        align-items: center;
        gap: .5rem;

        .devices-card_export {
          box-shadow: none;

          span {
            display: none;
          }
        }
  
        .close-button {
          border: 1px solid #C2D1E0;
        }
      }
    }
  
    .devices-card_main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .devices-card_inner {
        border-radius: 0 0 1.25rem 1.25rem;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .devices-card_list {
          flex: 1 1 auto;
          height: 0;
          padding: 0;
          margin-bottom: 0;
          overflow: auto;

          .data-table-component {
            height: unset;
            overflow: unset;
          }

          .data-table-component th {
            position: static;
          }

          .data-table-component th:nth-child(1) {
            padding-left: 0.6rem;
          }
  
          .table-cell:nth-child(1) .table-cell_constrained {
            padding-left: 0.6rem;
          }
  
          .table-cell:nth-child(2) {
            position: relative;
            z-index: 251;
          }
        }

        .devices-card_pagination {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: .5rem;
          border-top: 1px solid #C2D1E0;
          background-color: #f3f7fb;

          button {
            margin-left: 0;
          }
        }
      }
    }
  }

  .cluster-map {
    .device-stats {
      background-color: white;
      padding: 0.5rem;
      border: 2px solid;
      border-radius: 0.25rem;
      opacity: 0.3;
      transition-property: opacity, color, border-color;
      transition-duration:  0.25s;
      border-color: rgb(0 0 0 / 30%);
      color: black;

      &:hover {
        opacity: 1;
        color: black;
        border-color: rgb(0 0 0 / 30%);
      }
    }

    .bottom-left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding-right: 10px;
    }

    .cluster {
      fill: rgb(113 172 239 / 50%);
      fill-opacity: 1;
      stroke: rgb(25 121 230 / 100%);
      animation: fade-in 0.25s;
      animation-fill-mode: forwards;
  
      &.selected {
        fill: rgb(255 255 255 / 50%);
        stroke: rgb(255 255 255 / 100%);
      }
    }
  
    .cluster-label {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      font-size: 2rem;
      font-weight: bold;
      transform: translateX(50%);
      text-align: center;
      translate: 0% -50%;
      color: rgb(255 255 255 / 100%);
      -webkit-text-stroke-color: rgb(25 121 230 / 100%);
      -webkit-text-stroke-width: 1.5px;

      &::before {
        display: none;
      }

      &.selected {
        color: rgb(25 121 230 / 100%);
        -webkit-text-stroke-color: rgb(255 121 230 / 0%);
      }
      
      &.loading {
        color: rgb(255 255 255 / 0%);
        -webkit-text-stroke-color: rgb(25 121 230 / 0%);
      }
    }
  }
}
