/* #region Filter Section Control */

/* The animation code */
@keyframes fade-out {
  from { opacity: 0.2; }
  to { opacity: 1; }
}

.filter-selection-control {
  visibility: visible;
  opacity: 0.2;
  animation-name: fade-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.filter-selection-control .filter-footer {
  line-height: 2.2em;
}

.filter-selection-control .filter-footer .p-2 {
  display: flex;
}

.filter-selection-control .filter-footer .row {
  margin-right: 15px;
}

.filter-selection-control .filter-apply-block {
  padding: 5px;
}

/* #endregion */

/* #region Filter Group */
.filter-selection-group h3 {
  font-size: 1.1em;
  padding: 1em 0 0.3em;
  font-weight: 600;
  color: #797979;
  border-bottom: 2px solid #eee;
}

.filter-selection-group h4 {
  font-size: 1em;
  padding-bottom: 0.3em;
  font-weight: 600;
  color: #797979;
}

.filter-selection-group .filter-box {
  margin-left: 0.4em;
  vertical-align: middle;
}

.filter-selection-control .filter-box input {
  margin: 0.3em 0;
}

/* #endregion */

.filter-row {
  margin-right: 0;
  margin-left: 0;
}

/* #region filterColumn */

.filter-selection-column_container .filter-table {
  display: table;
  margin-left: 1px;
}

.filter-selection-column_container .filter-header {
  display: table-header-group;
}

.filter-selection-column_container .filter-row {
  display: table-row;
}

.filter-selection-column_container .filter-cell {
  display: table-cell;
  padding-bottom: 0.5rem;
}

.filter-selection-column_container .filter-cell--additional {
  padding-left: 0.5rem;
}

.filter-selection-column_container h3 {
  font-size: 1em;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.filter-selection-column_container .filter-column:not(:last-child) {
  border-right: solid;
  border-width: 2px;
  border-color: #ededed;
}

.filter-selection-column_container .filter-column {
  display: table-row-group;
}

.filter-selection-column_container .filter-column-scroll-wrapper {
  height: 380px;
  overflow-y: auto;
}

.filter-selection-column_container .filter-column__group {
  padding: 5px 0;
}

.filter-selection-column_container .filter-column__group-header {
  font-size: 1em;
  font-weight: 600;
  padding: 10px 0;
}

/* #endregion */

/* #region filterSelection */

.filter-selection .filter-count {
  position: relative;
  margin-top: 1.7em;
  margin-left: 1em;
}

/* #endregion */

.filter-id--status .filter-box i {
  margin-left: 0.5em;
  font-size: 1em; /* em rather than rem as icon should match size of current text context */
}

.filter-box.filter-box--italic {
  font-style: italic;
}

.filter-box {
  margin-bottom: 0.5rem;
}
