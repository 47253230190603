.checkbox {
  position: relative;
}

/* #region Fancy Style */
.fancy-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.fancy-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
}

.devices_list .fancy-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
}

.fancy-checkbox+label i.fas.fa-shield-alt {
  color: #81afda;
  padding-left: 0.5em;
}

.fancy-checkbox+label::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 1.125rem;
  height: 1.125rem;
  background: #fff;
  border-radius: 2px;
  margin: 0.1em 0.6em 0 0;
  border: 1px solid #adadad;
}

.fancy-checkbox:hover+label::before {
  border: 0;
  background: #a2d4a4;
}

.fancy-checkbox:checked+label::before {
  border: 0;
  background: #4ea753;
}

.fancy-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.checkbox:focus-within label::before {
  outline: auto;
}

.fancy-checkbox:disabled+label::before {
  box-shadow: none;
  background: #ddd;
}

.fancy-checkbox:checked+label::after {
  content: '';
  position: absolute;
  left: 0.2rem;
  top: 0.6rem;
  background: white;
  width: 0.15rem;
  height: 0.15rem;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

/* #endregion */
