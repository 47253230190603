.core-overview_container .chart-and-header-container_header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.core-overview__titles-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: baseline;
}

.core-overview_table-container__title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 2.5rem;
}

.core-overview_container .page-header__header .chart-and-header-container_header_right {
  font-weight: 600;
  font-size: 0.9375rem;
}

@media (width <= 600px) {
  .core-overview_container .page-header__header .chart-and-header-container_header_right {
    font-size: 0.75rem;
  }
}

.core-overview_container .data-table-component th:last-child {
  padding-right: 2.5rem;
}

.core-overview_container .data-table-component td:last-child {
  padding-right: 2.5rem;
}

.core-overview_container__estate-and-alerts {
  display: flex;
}

.core-overview_container .estate-container {
  min-height: 20rem; /* retain height for the estate-container while loading */
  flex: 50%;
}

.core-overview_container .alerts-container {
  flex: 50%;
}

.core-overview_container .horizontal-estate .chart-and-legend-container {
  width: 100%;
}

/* make loading spinner appear at middle of estate */
.estate-container .loading-container .loading-spinner {
  top: 0;
  margin-top: 8rem;
}

.estate-container_header {
  font-weight: 600;
  font-size: 0.9375rem;
  padding: 15px;
}

.core-overview_table-container {
  margin-top: 10px;
  margin-bottom: 1rem;
}

.core_device_overview_composition .fa-exclamation-triangle.enhanced-warning-icon {
  line-height: 0.7rem;
  margin-top: 0.2em;
}

.core-overview-message {
  margin-bottom: 0.75rem;
  display: block;
  overflow: auto;
}

.core-overview-message .estate-message-header {
  margin-top: 0.25rem;
  margin-left: 1.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}

.core-overview-message .estate-message-body {
  margin-left: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.core-overview-message .estate-message-text {
  margin-top: 2px;
  font-size: 1rem;
}
