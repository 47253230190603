.tooltip_popover-element {
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  background-color: white;
  padding: 10px;
  z-index: 1000;
  border-radius: 3px;
}

.tooltip_reference-element {
  cursor: pointer;
  display: inline-block;
}

.tooltip-arrow {
  width: 10px;
  height: 10px;
}

.tooltip-arrow::before {
  z-index: 1;
  content: '';
  position: absolute;
  display: block;
  background: #fff;
}

.tooltip-arrow::after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  transform: rotate(45deg);
}

.tooltip_popover-element[data-popper-placement^='left'] > .tooltip-arrow {
  right: 0;
}

.tooltip_popover-element[data-popper-placement^='left'] > .tooltip-arrow::before {
  right: 0;
  top: -5px;
  width: 10px;
  height: 20px;
}

.tooltip_popover-element[data-popper-placement^='left'] > .tooltip-arrow::after {
  right: -5px;
}

.tooltip_popover-element[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: 0;
}

.tooltip_popover-element[data-popper-placement^='top'] > .tooltip-arrow::before {
  bottom: 0;
  width: 20px;
  height: 10px;
  right: -5px;
}

.tooltip_popover-element[data-popper-placement^='top'] > .tooltip-arrow::after {
  top: 5px;
}

.tooltip_popover-element[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: 0;
}

.tooltip_popover-element[data-popper-placement^='bottom'] > .tooltip-arrow::before {
  top: 0;
  width: 20px;
  height: 10px;
  right: -5px;
}

.tooltip_popover-element[data-popper-placement^='bottom'] > .tooltip-arrow::after {
  bottom: 5px;
}

.tooltip_popover-element[data-popper-placement^='right'] > .tooltip-arrow {
  left: 0;
}

.tooltip_popover-element[data-popper-placement^='right'] > .tooltip-arrow::before {
  left: 0;
  top: -5px;
  width: 10px;
  height: 20px;
}

.tooltip_popover-element[data-popper-placement^='right'] > .tooltip-arrow::after {
  left: -5px;
}

.tooltip_popover-element .fa-exclamation-triangle.enhanced-warning-icon {
  line-height: 0.6em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
