.batteryCentric_batteries_estate .fa-exclamation-triangle.enhanced-warning-icon {
  line-height: 0.7em;
}

.batteryEssentialsTable_container .statusText {
  background-color: #fff;
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
}

.batteryEssentialsTable_container .loading-container {
  height: 45vh;
  background-color: #fff;
}

.batteryEssentialsTable_container i.fas.fa-shield-alt {
  color: #81afda;
}

.batteryEssentialsTable_container .data-table-component tr:nth-of-type(even) td {
  background-color: #fff;
}

.batteryEssentialsTable_container .data-table-component tr:nth-of-type(odd) td {
  background-color: #f7f7f7;
}

.batteryEssentialsTable_container .data-table-component {
  --column-width-1st-left: 0;
  --column-width-2nd-left: 226px;
  --column-width-3rd-left: 386px;
  --column-width-4th-left: 506px;
  --column-width-5th-left: 610px;
  --column-width-6th-left: 706px;
  --column-width-7th-left: 826px;
}


.batteryEssentialsTable_container .data-table-component th:first-child,
.batteryEssentialsTable_container .data-table-component td:first-child {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-1st-left);
}

@media (width >= 1200px) {
.batteryEssentialsTable_container .data-table-component {
  th:nth-child(2), td:nth-child(2) {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-2nd-left); 
  }

  th:nth-child(3), td:nth-child(3) {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-3rd-left);
  }

  th:nth-child(4), td:nth-child(4) {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-4th-left);
  }

  th:nth-child(5), td:nth-child(5) {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-5th-left);
  }

  th:nth-child(6), td:nth-child(6) {
  position: sticky;
  z-index: 1000;
  left: var(--column-width-6th-left);
  }

  th:nth-child(7), td:nth-child(7) {
    position: sticky;
    z-index: 1000;
    left: var(--column-width-7th-left);
  }  
 }
}
