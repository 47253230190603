.slider {
  padding: 0.5em;
}

.slider .slider-options div {
  padding: 0;
  text-align: center;
  vertical-align: text-bottom;
  position: absolute;
  display: inline-block;
  filter: opacity(0.5);
  white-space: nowrap;
}

.slider .slider-options div.selected {
  font-weight: 600;
  filter: opacity(1);
}

.slider .slider-options {
  height: 2em;
  position: relative;
}

/* style blue */
.slider.blue .StepRangeSlider__thumb {
  border: #5880a7;
  background-color: #5880a7;
}

.slider.blue .StepRangeSlider__track {
  background-color: #f7f7f7;
  border: 1px solid #e5e7ed;
}
