.section_container {
  margin-bottom: 1.5em;
}

.section_container:last-child {
  margin-bottom: 0.2em;
}

.section_title {
  text-align: left;
  margin-bottom: 0;
  font-weight: 600;
}

.section_line {
  border-top: 1px solid #d7e2eb;
  margin: 0 -1.25rem 1.5em;
}

.header_text {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
}
