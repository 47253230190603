.device-event-icon {
  color: #fff;
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
  border-radius: 2px;
  background-color: #8b96a2;
  font-size: 12px;
}

.device-event-icon.event-colour-blue {
  background-color: #5b9ad2;
}

.device-event-icon.event-colour-red {
  background-color: #d37878;
}

.device-event-icon.event-colour-green {
  background-color: #31aa4c;
}

.device-event-icon::before {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.device-event-icon.custom-rotate-45::before {
  transform: rotate(45deg);
}

.device-event-icon.custom-rotate-315::before {
  transform: rotate(315deg);
}

.device-event-icon.custom-icon::before {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}

.device-event-icon.custom-icon-drop::before {
  background-image: url('./dropIconWhite.svg');
}

.device-event-icon.custom-icon-time-error::before {
  background-image: url('./timeError.svg');
  background-size: 14px 14px;
}

.device-event-icon.custom-icon-battery-changed::before {
  background-image: url('./batteryChangedIcon.svg');
}

.device-event-icon.custom-icon-network-changed::before {
  background-image: url('./networkChangeIcon.svg');
}

.device-event-icon.custom-icon-sim-changed::before {
  background-image: url('./simChangeIcon.svg');
}

.device-event-icon.custom-icon-network-available::before {
  background-image: url('./networkAvailableIcon.svg');
}

.device-event-icon.custom-icon-network-lost::before {
  background-image: url('./networkLostIcon.svg');
}

.device-event-icon.custom-icon-bearer-changed::before {
  background-image: url('./bearerChangeIcon.svg');
}

.device-event-icon.custom-icon-signal-sharp-solid::before {
  background-image: url('./mobileRadioOff.svg');
}

.device-event-icon.custom-icon-signal-solid::before {
  background-image: url('./mobileRadioOn.svg');
}

/*
We do not rotate event icons in Safari,
since applying a transform to them causes them to
loose their correct position when rendered on a chart.
*/
body.browser-safari .device-event-icon {
  transform: none !important;
}

body.browser-safari .device-event-icon::before {
  transform: none !important;
}
