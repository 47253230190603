.estate-alerts {
  margin: 1.5rem;
}

.estate-alerts .estate-alerts__container {
  color: #333;
  font-family: 'Open Sans';
  height: 17rem;
  overflow-y: auto;
}

.estate-alerts .estate-alerts__container thead th {
  position: sticky;
  top: 0;
  background-color: white;
  text-align: right;
}

.estate-alerts .estate-alerts__container thead th:last-child {
  padding-right: 0.938rem;
}

.estate-alerts .estate-alerts__container thead th:first-child {
  text-align: left;
}

.estate-alerts .estate-alerts__title {
  font-size: 1.125rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.estate-alerts .estate-alerts__status {
  font-size: 1rem;
}

.estate-alerts .estate-alerts__status b {
  font-weight: 600;
}

.estate-alerts .estate-alerts__status td {
  padding: 0.5rem 0;
}

.estate-alerts .estate-alerts__status td:first-child {
  width: 100%;
  padding-left: 0.625rem;
}

.estate-alerts .estate-alerts__status td:not(:first-child) {
  text-align: right;
}

.estate-alerts .estate-alerts__status td:last-child {
  padding-left: 1.875rem;
  padding-right: 1rem;
}

.estate-alerts .estate-alerts__status tr:not(:last-child) {
  border-bottom: 1px solid #c2d1e0;
}

.estate-alerts .estate-alerts__status--no-alerts {
  font-size: 0.875rem;
  padding: 70px 0;
  text-align: center;
  font-style: italic;
}

.estate-alerts .loading-spinner {
  margin-top: 103px;
}
