
.core_device_container .tab-panel .nav-title {
  margin: 0;
}

.core_device_container .device-header {
  position: relative;
  margin-top: 1.5rem;
  margin-left: 2.5rem;
  margin-bottom: 0.75rem;
}

.core_device_container .device-id {
  font-weight: 600;
}

.core_device_container .device-header .title {
  font-weight: 700;
  font-size: 1.286em;
}

.core_device_container .inner_card,
.core-device_content {
  font-weight: 600;
}

.core-device-content_fixture .col-auto:not(:last-child)::after {
  background: #c2d1e0;
  bottom: 0;
  right: 0;
  top: 0;
  content: ' ';
  position: absolute;
  width: 1px;
}

.core-device-content_fixture .overview-component {
  justify-content: left;
}

.core-device-content_fixture {
  padding: 0.5rem 1.875rem 0;
}

.core-device-content_fixture .col-auto {
  flex-grow: 1;
}

.core-device_overview {
  padding: 1.25rem 0 0 1.875rem;
  font-size: 1.286em;
  font-weight: 700;
}

.core_device_container .inner_card {
  background-color: #f3f7fb;
  margin: 0.7143em;
  box-sizing: border-box;
  border-radius: 8px;
  border-left: 1px solid #d7e2eb;
  border-right: 1px solid #d7e2eb;
  border-bottom: 2px solid #d7e2eb;
}

.core_device_container .applications-overview-content_subtitle {
  font-weight: 400;
  padding-left: 1.875rem;
}

.core-device_tabs .tab-panel .nav-link {
  background-color: #dfe8ef;
  opacity: 1;
  margin-right: 0.714em;
  padding: 0.75rem 1.25rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.core-device_tabs .tab-panel .nav-link:hover {
  background-color: #c2d1e0;
  border: 1px solid #c2d1e0;
  transition: 0.2s;
}

.core-device_tabs .nav-tabs {
  border-bottom: 1px solid #c2d1e0;
}

.core-device_tabs .tab-panel .nav-link.active {
  color: #333;
  background-color: #fff;
  border: 1px solid #c2d1e0;
  border-bottom-color: #fff;
}

.core-device_tabs .tab-panel .nav-link.active:hover {
  border-color: #c2d1e0 #c2d1e0 #fff;
}

.core_device_container .table_part .data-table-component {
  max-height: 500px;
}

.core_device_container .pagination-bar-component {
  margin-top: 2px;
  box-shadow: 0 -1px 4px 0 #dadada;
  font-weight: 400;
}

.core_device_container .core-device-content_fixture .fa-spinner {
  opacity: 0.6;
}

.core_device_container .serialNumber-spacing {
  padding-left: 0.5em;
}

.core_device_container .devices-list_link {
  padding-right: 0.5em;
  color: #07c;
}

#core-devices-breadcrumb {
  font-size: 0.929em;
  color: #707070;
}

.core_device_container .devices-list_link:hover {
  padding-right: 0.5em;
  color: #07c;
  text-decoration-line: underline;
  cursor: pointer;
}

.core_device_container .rectangle-label {
  position: relative;
  top: -1px;
  margin-left: 0.5em;
}
