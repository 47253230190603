.tab-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-panel .nav-tabs {
  display: flex;
}

.tab-panel .nav-header {
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 2.4em;
  align-items: flex-end;
}

.tab-panel.start .nav-header {
  justify-content: flex-start;
  align-items: flex-start;
}

.tab-panel .nav-header li {
  text-align: center;
}

.tab-panel .nav-header i {
  font-size: 1.2em;
}

.tab-panel .tab-content-invisible {
  display: none;
}

.tab-panel .tab-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-panel .nav-header button.active,
.tab-panel .nav-header button:hover,
.tab-panel .nav-header a.active,
.tab-panel .nav-header a:hover {
  opacity: 1;
  filter: none;
}

.tab-panel .nav-header button,
.tab-panel .nav-header a {
  font-weight: 600;
  color: #333;
  cursor: pointer;
  height: 100%;
  background-color: #dfe8ef;
  opacity: 1;
  margin-right: 0.714em;
  padding: 0.75rem 1.25rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tab-panel .nav-link.active {
  color: #333;
  background-color: #fff;
  border: 1px solid #d7e2eb;
  border-bottom-color: #fff;
}

.tab-panel .nav-link:hover {
  background-color: #c2d1e0;
  border: 1px solid #c2d1e0;
  transition: 0.2s;
}

.tab-panel .nav-link.active:hover {
  border-bottom-color: #fff;
  background-color: #fff;
}

.nav-tabs {
  border-bottom: 1px solid #d7e2eb;
}

.tab-panel .nav-header button.centered .tab-panel .nav-header a.centered {
  align-content: center;
  flex-wrap: wrap;
  display: flex;
}

.tab-panel .nav-title {
  padding-right: 1em;
  margin: 0.2em;
  float: left;
  flex-shrink: 2;
}

.tab-panel .tab-content {
  flex-grow: 1;
}

.nav-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (width <= 1279px) {
  .tab-panel.default .nav-tabs {
    flex-direction: column;
  }

  .tab-panel.default .nav-title {
    padding-bottom: 0.5em;
  }
}

/* vertical */

.tab-panel.vertical .tab-panel-content {
  display: flex;
  flex-direction: row;
}

.tab-panel.vertical .tab-content {
  flex-grow: 1;
}

.tab-panel.vertical .nav-tabs {
  width: 10rem;
  flex-basis: 10rem;
  display: flex;
  flex-direction: column;
  border: none;
  flex-shrink: 0;
}

.tab-panel.vertical .nav-title {
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.tab-panel.vertical .nav-header {
  justify-content: left;
  display: flex;
  flex-direction: column;
}

.tab-panel.vertical .nav-link:focus {
  outline-offset: -2px;
}

.tab-panel.vertical .nav-header .nav-link {
  border: none;
  padding-left: 5px;
  width: 100%;
  text-align: left;
}

.tab-panel.vertical .nav-header li:nth-child(1) .nav-link {
  padding-top: 0;
}

.tab-panel.vertical .nav-header li button {
  display: flex;
  flex-flow: row nowrap;
}

.tab-panel.vertical .nav-header li button::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  flex-grow: 1;
  text-align: right;
  margin-left: 0.5rem;
}

@media (width <= 576px) {
  .tab-panel.vertical .nav-tabs {
    flex-shrink: 1;
  }

  .tab-panel.vertical .nav-link {
    padding-right: 0;
  }
}
