.estate .estate_chart {
  height: 20em;
  width: 30em;
  margin-left: 0;
  text-align: 'center';
}

@media (width <= 991px) {
  .estate .estate_chart {
    height: 10em;
  }
}

.estate .estate_chart .amcharts-AxisRendererX-group .amcharts-Label {
  font-family: 'Open Sans', 'Font Awesome 5 Free';
}

.estate .estate_chart .amcharts-Container .amcharts-Sprite-group .amcharts-RoundedRectangle-group {
  cursor: pointer;
}

.estate .estate_chart .amcharts-Container .amcharts-AxisRendererX .amcharts-AxisLabel {
  cursor: pointer;
}
