.core-device-performance-overview_composition {
  font-weight: 600;
  background-color: #f3f7fb;
  margin: 0.7143em;
  box-sizing: border-box;
  border-radius: 8px;
  border-left: 1px solid #d7e2eb;
  border-right: 1px solid #d7e2eb;
  border-bottom: 2px solid #d7e2eb;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .col-auto:not(:last-child)::after {
  background: #c2d1e0;
  bottom: 0;
  right: 0;
  top: 0;
  content: ' ';
  position: absolute;
  width: 1px;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture {
  padding: 0.875rem 1.875rem 0.4rem;
}

.core-device-performance-overview_composition .core-device-performance-overview_header {
  padding: 1.25rem 0 0 1.875rem;
  font-size: 1.286em;
  font-weight: 600;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture a {
  margin-right: 0.5em;
  font-size: 0.8em;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .overview-component {
  justify-content: left;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .overview-component .col-auto {
  flex-grow: 1;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .last-contact {
  font-size: 0.8em;
  margin-bottom: 2px;
  margin-top: 4px;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .fa-spinner {
  opacity: 0.6;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture i {
  font-size: 1em;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .red_icon {
  color: #dd7778;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .yellow_icon {
  color: #fbcd76;
}

.core-device-performance-overview_composition .core-device-performance-content_fixture .green_icon {
  color: #3fa67d;
}

.core-device-performance-overview_composition .panel-button.export-report {
  background-color: #0072af;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2rem;
  line-height: 2rem;
  box-shadow: 0 2px 4px 0 rgb(176 176 176 / 50%);
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  bottom: 0.5em;
  margin-right: 30px;
  float: right;
}

.core-device-performance-overview_composition .panel-button.export-report:hover {
  background-color: #004c89;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}
