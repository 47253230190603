:root {
  /* border radius sizes */
  --border-radius-sm: 3px;
  --border-radius-md: 5px;
  --border-radius-lg: 8px;

  /* font family */
  --font-family-standard: 'Open Sans', sans-serif;
  --font-family-icon: 'Open Sans', 'Font Awesome 5 Free', sans-serif;

  /* font sizes - based on base font size of 16px */
  --font-size-8px: 0.5rem;
  --font-size-10px: 0.625rem;
  --font-size-12px: 0.75rem;
  --font-size-13px: 0.813rem;
  --font-size-14px: 0.875rem;
  --font-size-15px: 0.938rem;
  --font-size-16px: 1rem;
  --font-size-18px: 1.125rem;
  --font-size-20px: 1.25rem;

  /* colors */
  --color-black: #333;
  --color-white: #fff;
  --color-light-blue: #07c;
  --color-red: #c03b3b;

  /* brand colors */
  --color-brand-blue: #4a76a0;

  /* text colors */
  --color-text-dark: var(--color-black);
  --color-text-light: var(--color-white);

  /* link colors */
  --color-link: var(--color-light-blue);
  --color-link-hover: #005eb3;

  /* border & background colors */
  --color-section-border: var(--color-component-dark-grey);
  --color-background-body: #e8edf0;
  --color-background-light: var(--color-component-light-grey);

  /* input colors */
  --color-input-border: #949494;
  --color-input-border-focus: var(--color-light-blue);
  --color-input-border-error: var(--color-red);
  --color-input-placeholder: #a3a3a3;

  /* status colors */
  --color-status-red: #dd7778;
  --color-status-yellow: #fbcd76;
  --color-status-green: #3fa67d;

  /* data colors */
  --color-data-dark-blue: #003a6d;
  --color-data-blue: #0072c3;
  --color-data-light-blue: #0094f0;
  --color-data-dark-green: #005d5d;
  --color-data-green: #007c7b;
  --color-data-light-green: #009d9a;
  --color-data-purple: #58508d;
  --color-data-magenta: #d45087;
  --color-data-pink: #dd729f;
  --color-data-orange: #c87f00;
}
