.about-popup .modal-body {
  height: unset;
}

.about-popup .modal-body .modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-popup .modal-body .modal_body p:last-child {
  margin-bottom: 0;
}

.about-popup .content-header {
  font-weight: 600;
}
