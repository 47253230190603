.core-devices_list-edit_record .modal-body {
  height: auto;
}

.core-devices_list-edit_record .modal_footer {
  width: 100%;
}

.devices-edit-record__list {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.devices-edit-record__item {
  display: table-row;
}

.devices-edit-record__title,
.devices-edit-record__value {
  display: table-cell;
  padding: 0.75rem 0;
}

.devices-edit-record__title {
  font-weight: 600;
  width: 140px;
  vertical-align: middle;
  padding-left: 0.625rem;
}

.devices-edit-record__item--home-location .devices-edit-record__value {
  position: relative;
}

.devices-edit-record__input {
  border: 1px solid #949494;
  border-radius: 3px;
  padding: 0.125rem 0.5rem;
}

.devices-edit-record__input--invalid {
  border: 1px solid #c03b3b;
}

.devices-edit-record__input--invalid:focus {
  outline-color: #c03b3b;
}

.devices-edit-record__input--valid:focus {
  outline-color: #07c;
}

.devices-edit-record__validation-err {
  padding-left: 1rem;
  color: #c03b3b;
  font-size: 0.875rem;
}

.devices-edit-record__validation-err--group {
  padding-left: 0;
}

/* #region home location input */

.devices-edit-record .autosuggest-dropdown-button__autosuggest {
  width: 360px;
}

@media (width <=600px) {
  .devices-edit-record .autosuggest-dropdown-button__autosuggest {
    width: calc(100% - 0.8rem - 0.8rem);
  }
}

.devices-edit-record .autosuggest-dropdown-button .group-tag-picker__menu--open {
  max-height: 115px;
}

/* #endregion home location input */

/* #region group tag input */

.devices-edit-record .devices-edit-record__groups-input--invalid .group-tag-picker__input-container {
  color: #c03b3b;
}

.devices-edit-record .react-tags {
  width: 360px;
}

@media (width <=600px) {
  .devices-edit-record .react-tags {
    width: 100%;
  }
}

/* #endregion group tag input */
