.eTable {
  margin-bottom: 0;
}

.eTable--container {
  box-shadow: 0 2px 4px 0 rgb(214 214 214 / 60%);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #fff;
}

.eTable thead {
  font-weight: 600;
  padding: 0.65em;
}

.eTable td {
  vertical-align: middle;
  padding: 0.65em;
}

.eTable td:nth-child(1) {
  padding-left: 2em;
}

.eTable td:last-child {
  padding-right: 1em;
}

.eTableBody:hover td,
.tableRow:hover td {
  background: #e7f2fc !important;
  color: #1f71a8;
}

.eTable thead td {
  background-color: #fff !important;
}

.eTable .shadeClassDark td {
  background: #f7f7f7;
}

.eTable .shadeClassLight td {
  background: #fff;
}

.eTableHeader {
  background-color: #4a76a0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: #fff;
}

.eTableHeader .tabs {
  display: inline-block;
  width: 100%;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  list-style-type: none;
}

.eTableHeader .tabs ul {
  padding-left: 0;
}

.eTableHeader .tabs>span {
  display: inline-block;
  height: 42px;
  padding: 8px 12px;
}

.eTableHeader .tabs li {
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 12px 22px;
  text-decoration: none;
  font-size: 1em;
  opacity: 0.5;
  cursor: pointer;
  position: relative;
}

.eTableHeader .tabs a:first-child li {
  border-top-left-radius: 0.5rem;
}

.eTableHeader .tabs li:hover,
.eTableHeader .tabs .active {
  background-color: #315d87;
  opacity: 1;
}

.eTableHeader .tabs .active::after {
  content: '';
  position: absolute;
  margin-left: -33%;
  margin-top: 2.3em;
  border: 0.5em solid transparent;
  border-bottom-color: #315d87;
  transform: rotate(180deg);
  z-index: 1;
}

.eTableHeader .tabs #ebt_all li {
  padding-left: 2.5em;
  padding-right: 2.5em;
}

.eTableHeader .tabs #ebt_all .active::after {
  margin-left: -18%;
}

.eTableHeader .tabs .faded:not(:hover) span {
  color: #fff;
}

.eTableHeader .panel-button {
  height: 2.125rem;
}

.circle_red {
  color: #dd7778;
  font-size: 1.2em;
}

.circle_orange {
  color: #fbcd76;
  font-size: 1.2em;
}

.circle_green {
  color: #3fa67d;
  font-size: 1.2em;
}

.eTableHeader .active .fa-times-circle,
.tabs #ebt_red:hover li {
  color: #dd7778;
}

.eTableHeader .active .fa-exclamation-triangle,
.tabs #ebt_orange:hover li {
  color: #fbcd76;
}

.eTableHeader .active .fa-check-circle,
.eTableHeader .active .fa-check,
.tabs #ebt_green:hover li {
  color: #3fa67d;
}

.tabText {
  color: #fff;
  margin-left: 5px;
  font-weight: 600;
}

.eTable i.fas.fa-sort-down,
.eTable i.fas.fa-sort-up {
  color: #c4c4c4;
}

.eTable td:first-child {
  padding-left: 2.5em;
}

.eTable i.fas.fa-sort-down {
  transform: translateY(10%);
  margin-left: -0.62em;
}

.eTable .sort-up-down {
  margin-left: 0.5em;
}

.eTable i.fas.fa-sort-up.active-sort,
.eTable i.fas.fa-sort-down.active-sort {
  color: #333;
}

.eTable.dataTable {
  text-align: left;
}

.eTable .textCenter {
  text-align: center;
}

.eTable i.fas.fa-shield-alt {
  color: #81afda;
}

@media (width <=767px) {
  .estate_container h1,
  .batteryDetails_container h1 {
    margin-top: 1em !important;
  }
}
