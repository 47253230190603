/* retain height for the charts-container while loading */
.last24HourCharts_container,
.last30DaysCharts_container,
.selectedDateCharts_container {
  min-height: 40rem;
}

.last24HourCharts_container .loading-container .loading-spinner,
.last30DaysCharts_container .loading-container .loading-spinner,
.selectedDateCharts_container .loading-container .loading-spinner {
  top: 15rem;
}
