.users_list td .no-home-location {
  font-style: italic;
}

.users_list td .all-worlds-access {
  font-style: italic;
}

.users_list .table_part .data-table-component td {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.users_list .table_part .data-table-component td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
