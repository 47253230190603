.popover {
  cursor: default;
  pointer-events: none;
  box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 2px;
  color: #333;
  font-family: "Open Sans";
  font-size: 0.9em;
}

.popover .arrow {
  margin: 0;
  font-size: 0.65em;
}

.popoverDeviceRow.popover {
  margin-top: 0.9em;
  font-weight: 600;
}

.popover .infoPopover {
  display: inline-block;
  padding: 0.7em;
}

.popoverDeviceRow.popover .arrow {
  height: 7.8em;
  left: calc(50% - 1.35em) !important;
}

.popoverDeviceRow.popover .arrow::after {
  transform: rotate(180deg);
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.8em 1.3em;
  border-color: transparent transparent #fff;
}

.popoverDeviceRow.popover .tablePopover {
  text-align: center;
  display: inline-block;
  padding: 0.7em;
}

.popoverDeviceRow.popover .infoPopover {
  text-align: center;
}

@media (width <= 1200px) {
  .popover .tablePopover {
    display: block;
  }

  .popover .infoPopover {
    display: block;
  }
}

@media (width <= 1200px) {
  .popoverDeviceRow.popover .arrow::after {
    margin-bottom: -12.9em;
  }
}

@media (width <= 760px) {
  .popoverDeviceRow.popover .arrow::after {
    margin-bottom: -15.2em;
  }
}

.popoverThresholds.popover {
  font-weight: 400;
}

.popoverThresholds.popover .arrow {
  top: 0%;
}

.popoverThresholds.popover .arrow::after {
  left: -12px;
  top: -5px;
  transform: rotate(270deg);
  border-style: solid;
  border-width: 0 1em 1em;
  border-color: transparent transparent #fff;
}

.popoverThresholds.popover .infoPopover {
  text-align: left;
  padding-left: 1.2em;
}
