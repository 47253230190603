.loading-container .loading-content.loading {
  filter: opacity(0%);
}

.loading-container .loading-content.loading.overlay {
  filter: grayscale(90%) blur(1px);
  height: 100%;
}

.loading-container .loading-spinner {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.loading-container .loading-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: #fff3;
  box-shadow: 0 0 5px 5px #fff3;
}

.loading-container {
  position: relative;
  height: 100%;
}
