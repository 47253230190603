/* modal styles to match DeleteDialogue */

.delete-home-locations-failure .modal-header {
  border-bottom: 0 none;
}

.delete-home-locations-failure .modal-footer {
  border-top: 0 none;
}

.delete-home-locations-failure .modal-content {
  max-width: 640px;
  margin: 0 auto;
}

.delete-home-locations-failure .modal-content .modal-body {
  height: auto;
}
