.elemez-landing__loading {
  margin: 50vh auto;
  text-align: center;
}

.landing-container {
  display: flex;
  justify-content: center;
}

.landing-container > .row {
  justify-content: center;
}

@media (width >= 992px) {
  #elemez-landing {
    max-width: 1400px;
  }
}

@media (width <= 991px) {
  #elemez-landing {
    max-width: 991px;
  }
}

@media (width <= 768px) {
  #elemez-landing {
    max-width: 740px;
  }
}
