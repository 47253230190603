.form-controls__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-controls__message {
  margin-right: 1rem;
}

.form-controls__status {
  display: flex;
  margin-left: auto;
  margin-right: 2rem;
}

.form-controls__saving i {
  margin-right: 5px;
}

.form-controls__error {
  display: block;
  color: var(--color-red);
  font-size: var(--font-size-14px);
  margin-bottom: 0.5rem;
}
