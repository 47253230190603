.build-user_modal .modal-body {
  height: auto;
}

.build-user_modal .modal-footer {
  z-index: 100;
}

.build-user_modal .modal_footer {
  width: 100%;
}
