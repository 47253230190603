
.form-field {
  padding: 0.25rem 0;
}

.form-field__label {
  display: inline-block;
  margin-right: 1rem;
  font-weight: var(--font-weight-semi-bold);
  cursor: pointer;
}

.form-field--disabled .form-field__label {
  cursor: auto;
}

.form-field--required .form-field__label::after {
  content: '*';
  display: inline-block;
  color: var(--color-red);
  margin-left: 0.25rem;
  font-weight: var(--font-weight-normal);
}

.form-field__input {
  display: inline-block;
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-sm);
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
}

.form-field__input--read-only {
  border: none;
  padding: 0;
}

.form-field__after-input {
  margin-left: -0.5rem;
  margin-right: 1rem;
}

/* disable number input up/down selection arrows */
.form-field__input[type='number']::-webkit-inner-spin-button,
.form-field__input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.form-field__input:focus {
  outline-color: var(--color-input-border-focus);
  border-color: var(--color-input-border-focus);
}

.form-field__input::placeholder {
  color: var(--color-input-placeholder);
}

.form-field--error .form-field__input {
  border: 1px solid var(--color-input-border-error);
}

.form-field--error .form-field__input:focus {
  outline-color: var(--color-input-border-error);
}

.form-field--disabled .form-field__input {
  opacity: 0.5;
}

.form-field__error {
  display: inline-block;
  color: var(--color-red);
  font-size: var(--font-size-14px);
}
