.modalPopup_component .modal-dialog {
  max-width: 900px;
}

.modalPopup_component .modal-body {
  height: 30em;
  max-width: 900px;
  padding: 15px 30px;
}

.modal-backdrop.modal-backdrop {
  opacity: 0.5;
}

.modalPopup_component .modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.modalPopup_component .modal-header {
  border-bottom: 1px solid #c2d1e0;
  padding: 1.25rem 1.875rem 0.75rem;
}

.modalPopup_component .modal-title {
  font-family: 'Open Sans', sans-serif;
  color: #333;
}

.modalPopup_component .modal-header .header_title {
  font-size: 1.125rem;
  font-weight: 600;
}

.modalPopup_component .modal-header .subheader_title {
  font-size: 0.875rem;
  font-weight: 400;
}

.modalPopup_component .modal-footer {
  border-top: 1px solid #c2d1e0;
  font-size: 0.875rem;
  font-family: 'Open Sans', sans-serif;
  z-index: 1000;
  padding: 0.75rem 1.875rem;
}

.modal-content {
  border: 1px solid #c2d1e0;
  border-radius: 0.5rem;
}
