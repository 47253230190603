.chip-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 1rem;
  border: none;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  padding: 0;
  outline: none;
}

.chip-button .chip__text {
  padding: 0 0.625rem;
}

.chip-button:disabled {
  opacity: 0.6;
  cursor: auto;
}

.chip-button:hover:not(.chip-button-active) .chip-button__count-divider::after {
  border-right: 2px solid #c2d1e0;
  cursor: pointer;
}

.chip-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgb(0 119 204 / 50%);
}

.chip-button-active,
.chip-button-active:hover {
  border: transparent;
  background-color: #edf6ff;
  color: #07c;
  font-weight: 600;
  box-shadow: 0 3px 3px 0 rgb(105 105 105 / 25%);
}

.chip-button .chip:hover:not(:disabled, .chip-active) {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  cursor: pointer;
}
