.modal-open .core-anomalies_list_filters.modal {
  overflow-y: hidden; /* This prevents the modal from being able to scroll off the screen due to long filter tables */
}

.core-anomalies_list_filters .modal-header {
  border-bottom: none;
}

.core-anomalies_list_filters .modal-body {
  height: auto;
  padding: 0;
}

.core-anomalies_list_filters .nav-tabs {
  padding-left: 1.875rem;
}

.core-anomalies_list_filters .nav-title {
  display: none;
}

.core-anomalies_list_filters .tab-content {
  padding: 15px 30px;
}
