.form-field-list {
  display: table;
  table-layout: fixed;
}

.form-field-list .form-field {
  display: table-row;
  padding: 0;
}

.form-field__label,
.form-field__input-wrapper {
  display: table-cell;
  padding: 0.75rem 0;
}

.form-field__label {
  padding-right: 1em;
}
