button.close.close-button {
  background: #f2f2f2;
  border-radius: 3px;
  width: 2rem;
  height: 2rem;
  opacity: 0.8;
  text-shadow: none;
  color: #333;
}

button.close.close-button:hover {
  background: #d9d9d9;
  transition: 0.3s;
  opacity: 1;
}
