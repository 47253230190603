.overview-component .col-auto:first-child::before {
  display: none;
}

.overview-component .overview-column_spacing {
  padding: 0;
}

.overview-component .overview-value_blue {
  font-size: 1.429em;
  font-weight: 600;
  color: #236a9a;
}

.overview-component .overview-label {
  font-weight: 600;
  font-size: 1em;
}

.overview-component .col-auto {
  margin-bottom: 1em;
}

.overview-component {
  justify-content: center;
}
