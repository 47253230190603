.noSelectionOverlay {
  position: absolute;
  z-index: 1000;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;

  /* For IE8 and earlier */
  text-align: center;
  vertical-align: middle;
  padding: 0 15px;
  left: 0;
}

.noSelectionOverlayInner {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  opacity: 0.8;
  filter: opacity(80%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noSelectionAdditionalText {
  font-weight: 400;
}

.noSelectionText {
  font-weight: 600;
}

.noSelectionMessage {
  padding-bottom: 1.8em;
}

.noSelectionOpaqueBackground {
  opacity: 1;
}

.noSelectionHeader {
  font-weight: 600;
  padding-top: 1.6em;
  padding-bottom: 1.6em;
}

.noSelectionOverlay i {
  font-size: 2.61rem;
  color: #4a76a0;
}
