.modalPopup_component.core-device-performance_edit-events .modal_body {
  padding-right: 0;
}

.modalPopup_component.core-device-performance_edit-events .modal-body {
  padding-right: 0;
}

.edit-events__modal__checkbox--display-all label span {
  position: relative;
  left: 0.5em;
}

.modalPopup_component.core-device-performance_edit-events .modal_footer {
  width: 100%;
}

.edit-events__modal__footer {
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.edit-events__modal__footer .subheader {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  width: 45%;
  text-align: left;
}

.edit-events__modal__footer .subheader .editing-text {
  font-weight: 600;
  display: inline;
  padding-right: 1em;
}

.edit-events__modal__category {
  font-weight: 600;
  font-size: 1.1em;
}

.edit-events__modal__groups {
  display: flex;
  flex-flow: row wrap;
}

.edit-events__modal__group-header {
  width: 240px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px;
}

.edit-events__modal__group {
  margin-right: 30px;
  padding-right: 30px;
  width: 267px;
  margin-bottom: 25px;
}

.edit-events__modal__group label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.edit-events__modal__group label span {
  display: flex;
  width: 85%;
}

.edit-events__modal__group:not(:nth-child(3n)) {
  border-right: 1px solid #e5e7ed;
}

.edit-events__modal__group:nth-child(3n) {
  margin-right: 0;
  padding-right: 30px;
}

.edit-events__modal__icon {
  margin-left: auto;
}

.edit-events__button {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: var(--color-component-text-dark);
  background-color: var(--color-background-light);
  border: 1px solid var(--color-component-dark-grey);
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-semi-bold);
  height: 2.125rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.375rem 0.625rem;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.edit-events__button:hover {
  background-color: var(--color-component-grey);
  color: var(--color-component-text-dark);
  transition: 0.3s;
}

.edit-events__button > i {
  line-height: 1.25rem;
}

@media (width <= 892px) {
  .edit-events__modal__group:not(:nth-child(2n)) {
    border-right: 1px solid #e5e7ed;
  }

  .edit-events__modal__group:nth-child(2n) {
    border: none;
    margin-right: 0;
    padding-right: 30px;
  }

  .edit-events__modal__group:nth-child(3n) {
    margin-right: 30px;
    padding-right: 30px;
  }
}

@media (width <= 595px) {
  .edit-events__modal__groups {
    overflow-y: scroll;
    height: 340px;
  }

  .edit-events__modal__groups .edit-events__modal__group {
    border-right: none;
  }

  .edit-events__modal__footer {
    display: block;
  }
}
