.events-shown {
  margin-right: 0.5rem;
}

.events-shown__title {
  font-weight: 600;
}

.events-shown__list {
  font-weight: 400;
}

.events-shown__list--none {
  font-style: italic;
}
