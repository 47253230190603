.bulk-actions-container {
  display: flex;
  justify-content: center;
}

@media (width <= 800px) {
  .home-location-change_selection {
    display: flex;
    flex-direction: column;
  }
}

.bulk-actions-container .card {
  width: 60em;
}

.bulk-actions-container .bulk-actions_header {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-bold);
  padding: 10px;
}

.bulk-actions {
  padding: 30px;
}

.bulk-actions .option-picker {
  margin: 10px 0;
}
