.details-card.battery-device .details-tab-header .selection-header {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.125rem;
}

.details-card.battery-device .details-tab-header .rounded-label {
  margin-left: 1rem;
  top: -1px;
  position: relative;
}

.details-card.battery-device .details_row > div {
  margin-bottom: 1.1em;
  text-align: left;
}

.details-card.battery-device .mini-header {
  margin-bottom: 1.25rem;
  background-color: #f3f7fb;
  border: 1px solid #d7e2eb;
  padding: 0.25rem;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.details-card.battery-device .mini-header i {
  margin-right: 0.5em;
  color: #4a76a0;
}

.details-card.battery-device .details_right {
  padding-left: 1.2em;
  padding-right: 1.65em;
  text-align: left;
}

@media (width <= 767px) {
  .details-card.device .mini-header {
    text-align: right;
  }
}

.details-card.battery-device .details_information {
  padding: 1rem;
  text-align: center;
}

.details-card.battery-device .details_information .battery-information .nav-link {
  color: #333;
  opacity: 1;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 50px;
  padding: 0.35rem 1.25rem;
  line-height: 1.25rem;
}

.details-card.battery-device .details_information .battery-information .nav-link.active,
.details-card.battery-device .details_information .battery-information .nav-link.active:hover {
  border: 1px solid #07c;
  background-color: #edf6ff;
  color: #07c;
  font-weight: 600;
  box-shadow: 0 3px 3px 0 hsl(0deg 0% 41% / 25%);
}

.details-card.battery-device .details_information .battery-information .nav-link:hover {
  background-color: #d7d7d7;
  border-color: #d7d7d7;
}

.details-card.battery-device .details_information .battery-information .nav-tabs {
  text-align: center;
  background-color: #fff;
  border-bottom: none;
}

.details-card.battery-device .details_information .battery-information .nav-title {
  display: none;
}

.details-card.battery-device .details_information .battery-information .tab-panel .nav-header {
  justify-content: center;
}

.details-card.battery-device .details_information .battery-information .tab-panel .tab-content {
  padding-top: 1em;
}

.details-card.battery-device .details_information .battery-information .tabs .mini-header {
  margin-bottom: 0;
}

.discharge_chart .chart-container {
  height: 220px;
}

.discharge_chart .chart-title {
  margin-left: 1.5rem;
  margin-bottom: 1.25rem;
  padding-top: 1.5rem;
  font-size: var(--font-size-14px);
  font-weight: 700;
}

.discharge_chart .title_updated {
  font-weight: 400;
}
