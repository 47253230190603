.agreement-form {
  font-size: 0.875rem; /* 14px */
}

.agreement-form__agreement:not(:first-child) {
  margin-top: 1em;
}

.agreement-form__agreement__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5em;
}

.agreement-form__agreement__title {
  font-size: 1.125rem; /* 16px */
  font-weight: 900;
}

.agreement-form__sign-out,
.agreement-form__agreement__link {
  color: #07c;
}

.agreement-form__sign-out:hover,
.agreement-form__agreement__link:hover {
  color: #006dc2; /* 4% darkened */
  text-decoration: none;
}

.agreement-form__agreement__text {
  max-height: 250px;
  overflow-y: auto;
  padding: 1em;
  border: solid 1px #c2d1df;
  white-space: pre-line;
}

.agreement-form__checkboxes-header {
  padding: 1em; /* left padding to match left padding of __text */
}

.agreement-form__checkboxes-wrapper {
  display: flex;
  justify-content: center;
}

.agreement-form__checkboxes-description {
  padding: 1em; /* left padding to match left padding of __text */
}

.agreement-form__checkboxes {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.agreement-form__error {
  margin-top: 1em;
  text-align: center;
}

.agreement-form__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.agreement-form__sign-out {
  cursor: pointer;
}

.agreement-form__accept {
  margin-left: 2em;
  background-color: #07c;
}

.agreement-form__accept:hover {
  background-color: #006dc2; /* 4% darkened */
}

.agreement-form__accept:disabled,
.agreement-form__accept:disabled:hover {
  background-color: #07c;
  opacity: 0.4;
  cursor: initial;
}

.agreement-form__employee_details {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.agreement-form__input {
  border: 1px solid #c2d1e0;
  border-radius: 3px;
  width: 100%;
}

.agreement-form__employee_details i {
  font-size: 0.8rem;
}

.agreement-form__job_title,
.agreement-form__employee_name {
  flex: 0 1 100%;
  padding: 0.5em 1em;
}

@media (width <= 450px) {
  .agreement-form__employee_details {
    flex-wrap: wrap;
  }
}
