.estate .card .card-body {
  padding: 1rem;
}

.estate_container h1,
.discharge_container h1 {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0.25rem;
}

.discharge_container .legend {
  overflow-wrap: normal;
  text-align: center;
  margin-bottom: 1em;
}

.estate_container .total,
.discharge_container .lastDays {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.discharge_container .lastDays {
  margin-bottom: 1.5em;
}

.discharge_container .discharge_chart {
  height: 220px;
  margin: 0.5em 1em;
}

@media (width >= 1200px) and (width <= 1400px) {
  .devices.discharge_container .discharge_chart {
    width: 800px;
  }
}

@media (width <= 1200px) {
  .devices.discharge_container .discharge_chart {
    width: 400px;
  }
}

@media (width >= 790px) and (width <= 991px) {
  .devices.discharge_container .discharge_chart {
    width: 650px;
  }
}

.devices.discharge_container h1 {
  margin-top: 1.5em;
}

.details_container {
  text-align: center;
}

.tableRow {
  cursor: pointer;
  width: 100% !important;
}

.selectedRow td {
  background: #e7f2fc !important;
  color: #1f71a8;
}

.selectedRow {
  border-left: 6px solid #3fa0dc;
}

.batteryDetails {
  font-size: 0.9em;
}

.batteryDetails .right {
  text-align: right;
  padding-top: 1em;
  white-space: nowrap;
}

.batteryDetails .left {
  font-weight: 600;
  padding-top: 1em;
  white-space: nowrap;
}

.status_label {
  margin-top: -1.7em;
  margin-left: -1.7em;
  font-size: 0.9em;
  font-weight: 600;
  width: 16em;
  height: 2em;
  position: relative;
  text-align: center;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  line-height: 2em;
  color: #fff;
}

.green_status {
  background-color: #3fa67d;
}

.orange_status {
  background-color: #fbcd76;
  color: #747474;
}

.red_status {
  background-color: #dd7778;
}

.device-warning-icon {
  padding-left: 1em;
}

.green_status::after,
.orange_status::after,
.red_status::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 2em 1.2em;
  border-color: transparent transparent #fff;
}

@media (width >= 768px) {
  .estate {
    height: 100%;
  }
}
