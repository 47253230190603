.core-estate-overview__filters--buttons {
  margin-left: 2.875rem;
  margin-right: 2.875rem;
}

.core-estate-overview__filters--title {
  font-weight: 600;
  margin-top: 1.25rem;
  margin-left: 3.125rem;
  margin-bottom: 0.5rem;
}

.core-overview_container .data-table-component td:nth-child(3) {
  position: relative;
}

.core-estate-overview__table {
  margin-top: 1em;
}

.core-estate-overview .chip-button {
  margin: 0.375rem 0.25rem;
}

.core-estate-overview .highlight-red,
.core-estate-overview .highlight-yellow {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;
  text-align: right;
  position: absolute;
  inset: 0;
}

.core-estate-overview .highlight-red svg,
.core-estate-overview .highlight-yellow svg {
  align-self: center;
  margin-left: 3px;
}

.core-estate-overview .highlight-red svg path {
  fill: #a52222;
}

.core-estate-overview .highlight-red {
  color: #a52222;
  background-color: #f5c8c8;
}

.core-estate-overview .highlight-yellow {
  color: #7d6a17;
  background-color: #f5edc8;
}

.core-estate-overview .highlight-yellow svg path {
  fill: #7d6a17;
}

.core-estate-overview__table-header {
  display: flex;
  justify-content: space-between;
}
