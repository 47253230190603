.location-info-dialog .modal-header {
  border-bottom: none;
}

.location-info-dialog .modal-body {
  height: unset;
}

.location-info-dialog .modal-footer {
  border-top: none;
}

.location-info-dialog .modal-body p {
  margin-bottom: 0;
}

.location-info-dialog .modal-body .checkbox_confirmation_message {
  padding: 10px;
}

.location-info-dialog .modal-body .checkbox-with-message {
  padding-top: 20px;
}
