.reset-zoom__button {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: var(--color-component-text-dark);
  background-color: var(--color-background-light);
  border: 1px solid var(--color-component-dark-grey);
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-semi-bold);
  height: 2.125rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.375rem 0.625rem;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.reset-zoom__button:hover {
  background-color: var(--color-component-grey);
  color: var(--color-component-text-dark);
  cursor: pointer;
}

.reset-zoom__button > i {
  line-height: 1.25rem;
  margin-right: 0.15rem;
}

.reset-zoom__button--disabled,
.reset-zoom__button--disabled:hover {
  background-color: var(--color-background-light);
  opacity: 0.6;
  box-shadow: none;
  cursor: auto;
}
