.card {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border: none;
  font-family: "Open Sans";
  border-radius: 0.5rem;
}

.card .card-body.no-padding {
  padding: 0;
}

.card--narrow {
  max-width: 640px;
}

.card--rounded {
  border-radius: 0.25rem;
}

.card--centered {
  margin: 0 auto;
}

.card--newlines {
  white-space: pre-line;
}

@media (width >= 977px) {
  .card {
    height: 100%;
  }
}
