.core-device_performance.core-device_content hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #d7e2eb;
}

.performance-events-list-text_button {
  background-color: #fff;
  color: var(--color-component-text-dark);
  border: none;
  text-align: center;
  width: auto;
  font-size: var(--font-size-14px);
  cursor: pointer;
}

.performance-events-list-icon_button {
  color: var(--color-component-text-dark);
  background-color: var(--color-background-light);
  border: 1px solid var(--color-component-dark-grey);
  float: right;
  border-radius: var(--border-radius-sm);
  padding: 0 0.675rem;
  line-height: 2rem;
  text-align: center;
  font-size: var(--font-size-14px);
  cursor: pointer;
  margin-right: 2.5rem;
}

.performance-events-list-icon_button:hover {
  background-color: var(--color-component-grey);
}

.performance-events-list-icon_button:focus {
  outline: none;
}

.performance-events-list_table .data-table-component {
  font-weight: normal;
}

.events-list-icon::before {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.events-list-text {
  margin-left: 14px;
}

@media (width <= 1200px) {
  .performance-events-list-text_button {
    display: none;
  }
}
