.rounded-label {
  font-size: 0.9em;
  line-height: 1.5em;
  border-radius: 1.5em;
  display: inline-block;
  padding: 0.2em 1.1em 0.18em 0.85em;
  font-weight: 600;
}

.rounded-label i {
  margin-right: 0.5em;
}

.rounded-label.green {
  background-color: #3fa67d;
  color: white;
}

.rounded-label.red {
  background-color: #dd7778;
  color: white;
}

.rounded-label.yellow {
  background-color: #fbcd76;
  color: #333;
}

.rounded-label.blue {
  background-color: #81afda;
  color: white;
}
