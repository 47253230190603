.landing-item__container {
  text-align: center;
  margin-bottom: 2em;
}

.active__item:hover {
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px 0 hsl(0deg 0% 0% / 20%);
  transition: 0.2s;
}

.landing-item__container a {
  color: inherit;
}

.landing-item__container a:hover {
  text-decoration: none;
}

.landing-item-license {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 0.05em 0.4em;
  color: #fff;
  border-radius: 0.3em 0 0 0.3em;
  line-height: 1.25rem;
}

.landing-item-license__true {
  background-color: #4bb95a;
}

.landing-item-license__false {
  background-color: #939393;
}

.landing-item__title h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5em;
}

.landing-item__subtitle {
  margin-bottom: 0.5em;
  font-size: 0.938rem;
}

.landing-item__image {
  margin: 2em 0;
}

.landing-item__image img {
  width: 40%;
  max-width: 18em;
}
