/* #region WIP common selectors for charts with events */

.core-device_signal-strength .chart-header
 {
  display: flex;
  justify-content: space-between;
}

.core-device_signal-strength .chart-header-left,


.core-device_signal-strength .chart-edit-events {
  display: flex;
}

.core-device_signal-strength .chart-and-legend-container {
  display: inline;
}

.core-device_signal-strength .noSelectionOverlay {
  padding: 0;
}

.core_device_container .card {
  overflow: visible;
}


.key-green {
  height: 10px;
  width: 10px;
  background-color: #0a5;
  display: inline-block;
}



.core-device_signal-strength .chart-title {
  display: flex;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  min-width: fit-content;
}

.core-device_signal-strength {
  margin: 0.5rem 0.5rem 1.75rem;
}

.core-device_signal-strength-chart-container {
  margin-top: 0 !important;
}

.chart-edit-events .events-shown {
  margin-right: 1em;
}


.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject {
  width: 20px;
  height: 20px;
  overflow: visible;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='16'] .total-events-overlay-number {
  width: 20px;
  margin-left: -2px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='16'] {
  width: 21px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject[width='9'] .total-events-overlay-number {
  width: 20px;
  margin-left: -5px;
}

.chart-container .amcharts-LabelBullet .amcharts-Label foreignObject > div {
  overflow: visible !important;
}

.total-events-overlay-number {
  background-color: #5b9ad2;
  padding: 1px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 20px;
}

.chart-container__event-overlay-icon.icon-position-lower {
  margin-top: 5px;
}

.chart-container__event-overlay-icon::before {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.chart-container__event-overlay-tooltip-header {
  font-weight: 600;
}

.chart-container__event-overlay-tooltip-text-block {
  display: flex;
  flex-direction: column;
}

.chart-container__event-overlay-tooltip-text-and-icon {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.chart-container__event-overlay-tooltip-text-and-icon .device-event-icon {
  margin-bottom: auto;
}

.chart-container__event-overlay-tooltip-text {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
}

.chart-container__event-overlay-tooltip-message {
  margin-left: 9px;
  margin-top: 3.5px;
}

.event-overlay-tooltip-separator {
  margin-top: 5px;
  border-bottom: 1.5px solid #d7e2eb;
}

.tooltip-max-events-text {
  font-weight: 600;
}

/* #endregion */

/* Media query for when chart legends change orientation to account for smaller viewports */

@media (width <= 818px) {
  .chart-edit-events .events-shown {
    display: none;
  }
}
