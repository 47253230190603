.threshold-selection__header {
  margin: 1rem 0;
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-semi-bold);
}

.threshold-selection__header .info-popover-icon {
  font-size: var(--font-size-14px);
}

.threshold-selection__selector {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.threshold-selection__selector-input {
  margin-right: 0.75rem;
  cursor: pointer;
}

.threshold-selection__selector-label {
  margin-bottom: 0; /* override global bottom margin */
  cursor: pointer;
}

.threshold-selection__thresholds {
  margin-left: 2rem;
}

.threshold-selection__thresholds .form-field--disabled .form-field__label,
.threshold-selection__thresholds .form-field--disabled .form-field__after-input,
.threshold-selection__thresholds .form-field--disabled .form-field__error {
  opacity: 0.5;
}

.threshold-selection__thresholds .form-field--field-distanceYellow .form-field__input,
.threshold-selection__thresholds .form-field--field-distanceRed .form-field__input {
  width: 50px;
}

.threshold-selection__thresholds .form-field--field-distanceYellow .form-field__label i,
.threshold-selection__thresholds .form-field--field-distanceRed .form-field__label i {
  margin-right: 0.5em;
}
