.action-denied-users-list .modal-header {
  border-bottom: 0;
}

.action-denied-users-list .modal-footer {
  border-top: 0;
}

.action-denied-users-list .modal-content {
  max-height: 200px;
}
