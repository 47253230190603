html {
  height: 100%;
}

body {
  background-color: #e8edf0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  font-size: 14px;
}

.page {
  margin: 1.5em;
  padding-bottom: 1em;
}
