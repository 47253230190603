#enrollment_container .header {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
}

#enrollment_container .header_text {
  margin-left: 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 600;
}

#enrollment_container .header_subtitle_text {
  font-size: 0.725rem;
  font-weight: 400;
}
