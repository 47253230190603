.horizontal-estate .chart-and-legend-container {
  background-color: #f3f7fb;
  border-left: 1px solid #d7e2eb;
  border-right: 1px solid #d7e2eb;
  border-bottom: 2px solid #d7e2eb;
  border-radius: 8px;
  width: 1000px;
  margin: auto;
}

.horizontal-estate .card {
  box-shadow: none;
}

.horizontal-estate .chart-container {
  padding: 0 3em;
}

.horizontal-estate .currently-alerting-message {
  font-weight: 600;
  margin: 0 0.25em;
}

.horizontal-estate .details-message a {
  color: #07c;
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 1em;
  line-height: 1.25rem;
  border: 1px solid #c2d1e0;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  display: inline-block;
}

.horizontal-estate .estate-overview-chart-legend {
  padding-top: 20px;
  text-align: center;
}

.horizontal-estate .estate-overview-chart-legend .warning {
  padding: 0 0.5em;
  border-right: 1px solid #c2d1e0;
  font-weight: 600;
}

.horizontal-estate .estate-overview-chart-legend .problem {
  padding-right: 0.5em;
  border-right: 1px solid #c2d1e0;
  font-weight: 600;
}

.horizontal-estate .estate-overview-chart-legend .good {
  padding-left: 0.5em;
  font-weight: 600;
}

.horizontal-estate .estate-overview-chart-legend .fa {
  font-size: 1.25rem;
  margin: 0 0.6rem;
  position: relative;
  top: 2px;
}

.estate-overview-chart-legend .total {
  margin: 0 0.5em;
  font-size: 1.286em;
  position: relative;
  top: 1px;
}

.estate-overview-chart-legend .total a {
  color: #07c;
}

.estate-overview-tooltip-text .key-problem {
  height: 10px;
  width: 10px;
  background-color: #dd7778;
  margin-right: 0.5em;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.estate-overview-tooltip-text .key-warning {
  height: 10px;
  width: 10px;
  background-color: #fbcd76;
  margin-right: 0.5em;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.estate-overview-tooltip-text .key-good {
  height: 10px;
  width: 10px;
  background-color: #3fa67d;
  margin-right: 0.5em;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.estate-overview-tooltip-text .counts {
  font-weight: 600;
}

@media (width <= 1400px) {
  .horizontal-estate .chart-and-legend-container {
    width: 700px;
  }
}

@media (width <= 800px) {
  .horizontal-estate .chart-and-legend-container {
    width: 400px;
  }
}
