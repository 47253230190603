.display-thresholds-header {
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.display-thresholds-header h6 {
  font-size: 1.072em;
  font-weight: 600;
  padding-left: 0.4rem;
  display: inline;
}

.display-thresholds-header .threshold-icon {
  font-size: 0.8rem;
  color: #8aa8c1;
  padding-left: 0.3rem;
  cursor: pointer;
}

.display-thresholds-header .textCenter {
  display: inline;
}

.display-thresholds-section {
  padding: 10px;
  display: flex;
}

.display-thresholds-section.period input {
  margin: 0 1em 0 3.8em;
}

.display-thresholds-section.warning input {
  margin: 0 1em 0 2em;
}

.display-thresholds-section.problem input {
  margin: 0 1em 0 2.1em;
}

.display-thresholds-section .status-header i {
  position: relative;
  right: 3px;
  height: min-content;
  margin-right: 3px;
  top: 2px;
}

.display-thresholds-section .status-header {
  display: flex;
  font-weight: 600;
}

.display-thresholds-section .status-header.period {
  padding-left: 1.5em;
}

.display-thresholds-row {
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.display-thresholds-row input {
  height: min-content;
}
