input[data-id='essentials-header-search_input'] {
  padding-right: 18px;
}

.dataTable {
  text-align: center;
}

.batteryTable tbody .divider {
  border-left: 5px solid #fff;
}

.header-container .searchBox__clear-button {
  background-color: #5880a7;
  color: #f0f0f0;
  right: 43px;
  top: 4px;
}
