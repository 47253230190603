.threshold-settings-composition .alarms-configuration {
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
}

.threshold-settings-composition .alarms-configuration-header h6 {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.4rem;
  display: inline;
}

.threshold-settings-composition .alarms-configuration-header {
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
}

.threshold-settings-composition .alarms-configuration_table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.threshold-settings-composition .alarms-configuration_table td {
  padding-right: 2rem;
}

.threshold-settings-composition .alarms-configuration-body {
  padding-left: 1.15rem;
  font-size: 0.875rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.alarms-configuration-body tr:nth-child(1) {
  font-weight: 600;
  font-size: 0.875rem;
}

.alarms-configuration-body td {
  text-align: center;
}

.alarms-configuration-body .alert-types_column,
.alarms-configuration-body .alert-types_header {
  text-align: left;
}

.alarms-configuration .alarms-configuration-icon {
  color: #7490b9;
  margin-left: 0.375rem;
  font-size: 0.8rem;
}

.alarms-configuration .tooltip_popover-content {
  width: 500px;
  line-height: 25px;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: left;
}

.alarms-configuration .tooltip_popover-content p {
  margin-bottom: 0;
}
