.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-div-icon i {
  color: white;
  text-shadow: 0 2px 4px #424242;
  font-size: 20px;
}

.map-icon {
  background-image: url('./pins/pin_greyed-out.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  width: 28px;
  height: 40px;
}

path.no-service {
  stroke: rgb(0 0 0);
  fill: url("#pattern-stroke");
}

path.total-bars-5.bars-4 {
  stroke: rgb(149 193 87);
}

/* #region map icons */

.map-icon.icon-pin_home-location {
  background-image: url('./pins/icon-pin_home-location.svg');
}

.map-icon.icon-pin_device {
  background-image: url('./pins/icon-pin_device.svg');
}

.map-icon.no-service {
  background-image: url('./pins/pin_no-service.svg');
}

.map-icon.greyed-out {
  background-image: url('./pins/pin_greyed-out.svg');
}

.map-icon.total-bars-5.bars-0 {
  background-image: url('./pins/pin_0-bars.svg');
}

.map-icon.total-bars-5.bars-1 {
  background-image: url('./pins/pin_1-bars.svg');
}

.map-icon.total-bars-5.bars-2 {
  background-image: url('./pins/pin_2-bars.svg');
}

.map-icon.total-bars-5.bars-3 {
  background-image: url('./pins/pin_3-bars.svg');
}

.map-icon.total-bars-5.bars-4 {
  background-image: url('./pins/pin_4-bars.svg');
}

.map-icon.total-bars-5.bars-5 {
  background-image: url('./pins/pin_5-bars.svg');
}

.map-icon.total-bars-4.bars-0 {
  background-image: url('./pins/pin_0-bars.svg');
}

.map-icon.total-bars-4.bars-1 {
  background-image: url('./pins/pin_1-bars.svg');
}

.map-icon.total-bars-4.bars-2 {
  background-image: url('./pins/pin_2-bars.svg');
}

.map-icon.total-bars-4.bars-3 {
  background-image: url('./pins/pin_4-bars.svg');
}

.map-icon.total-bars-4.bars-4 {
  background-image: url('./pins/pin_5-bars.svg');
}

/* #endregion */

.leaflet-container .animated-marker {
  animation: bounce infinite 2s;
  animation-iteration-count: 1;
}

@keyframes bounce {
  0% { transform: translateY(0); }
  20% { transform: translateY(-12px); }
  40% { transform: translateY(12px) scale(1.2, 0.6); }
  60% { transform: translateY(-12px); }
  80% { transform: translateY(12px) scale(1.2, 0.6); }
  90% { transform: translateY(-9px); }
  100% { transform: translateY(0); }
}

.map-icon i {
  font-size: 14px;
  display: block;
  padding-top: 10px;
}

.leaflet-top, .leaflet-bottom {
  z-index: 999;
}

.leaflet-control .mapResetButton {
  background: white;
  border: 2px solid rgb(0 0 0 / 30%);
  padding: 9px;
  border-radius: 4px;
  cursor: pointer;
}

.leaflet-control .mapResetButton:hover {
  background: #f4f4f4;
}

@media (width <= 767px) {
  .leaflet-container {
    height: 10em;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .leaflet-container {
    height: 17em;
  }
}

@media (width >= 992px) and (width <= 1200px) {
  .leaflet-container {
    height: 21em;
  }
}

@media (width >= 1200px) {
  .leaflet-container {
    height: 25.3em;
  }
}
