.center_container {
  width: 40em;
  margin-top: 3em;
}

.signInImg {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}

.formHeader {
  margin-top: 1em;
  margin-bottom: 1em;
}

.formSubHeader {
  margin: 1.5em auto;
}

.formText {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
  font-size: 0.9em;
}

.alignCenter {
  text-align: center;
}

.redirectBtn {
  color: #269fcd;
  cursor: pointer;
}

.redirectBtn:hover {
  text-decoration: underline;
}

.btnAlign {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  margin: 1em auto;
  background-color: #5f8bb5;
  border: none;
  color: white;
  padding: 8px;
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(105 105 105 / 75%);
}

.btn:disabled {
  cursor: initial;
}

.btn:hover:not(:disabled),
.btn:active {
  background-color: #6c96be;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  background-color: #a0bdd9;
}

.card2 {
  margin-top: 10px;
}

.form-control {
  border-width: 1px;
  border-color: #e5e9ec;
  border-radius: 0 3px 3px 0;
  box-shadow: none;
  margin: 0 !important;
}

.form-control:focus {
  border-color: #5fc1ef;
  box-shadow: none;
}

.form-group {
  margin-bottom: 1.5rem;
}

.input-group-text {
  border-radius: 3px 0 0 3px;
  border-width: 0;
  color: #929699;
  background-color: #e5e9ec;
}

.input-group input:-webkit-autofill ~ label {
  transition: all 0.2s;
}

#authError,
#blankError,
#matchError {
  max-width: 24em;
  line-height: 2em;
  margin-left: auto;
  margin-right: auto;
  color: #c12f2f;
  background-color: #fbedec;
  border-radius: 3px;
}
