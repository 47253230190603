.core-devices_header_controls {
  display: flex;
  align-self: center;
}

.core-devices-list .filter-status-block {
  margin-left: 2.95em;
}

.core-devices-list .alerts-panel {
  padding: 0;
}

.core-devices-list .alerts-panel__alerts {
  margin-top: 1px;
  max-height: none;
}

.core-devices-list .tooltip_popover-content {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
}

.core-devices_list .table_part .data-table-component th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1000;
}

.core-devices_list .table_part .data-table-component td:first-child {
  position: sticky;
  left: 0;
  z-index: 1000;
}

.core-devices_list .data-table-component tr:nth-of-type(even) td:first-child {
  background-color: #fff;
}

.core-devices_list .data-table-component tr:nth-of-type(odd) td:first-child {
  background-color: #f7f7f7;
}

.core-devices_list .table_part .data-table-component th[data-key='deviceStatus'] {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1000;
}

.core-devices_list .table_part .data-table-component td[data-key='deviceStatus'] {
  font-size: 1rem;
  line-height: 1rem;
  position: sticky;
  right: 0;
  z-index: 1000;
}

.core-devices_list .data-table-component tr:nth-of-type(even) td[data-key='deviceStatus'] {
  background-color: #fff;
}

.core-devices_list .data-table-component tr:nth-of-type(odd) td[data-key='deviceStatus'] {
  background-color: #f7f7f7;
}

.core-devices-list_link {
  color: #07c;
}

.core-devices-list_link:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

.core-devices-list hr {
  border-top: 1px solid #d7e2eb;
  margin-top: 10px;
}

.core-devices_list .table_part .tooltip_popover-content {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
}

.core-devices_list .table_part .tooltip_popover-content .core-devices_list-data_usage-popover_text {
  font-size: 0.75rem;
}

.core-devices_list .table_part .alerts-panel {
  padding: 0;
}

.core-devices_list .table_part .alerts-panel__alerts {
  margin-top: 1px;
  max-height: none;
}

.core-devices_list .table_part .core-devices_list-data_usage-icon {
  color: #7490b9;
  margin-left: 0.375rem;
}
