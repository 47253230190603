.batteryCentric_devices_list {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.batteryCentric_devices_list td:nth-child(8) {
  text-align: right;
}

.batteryCentric_devices_estate .fa-exclamation-triangle.enhanced-warning-icon {
  line-height: 0.7em;
}
