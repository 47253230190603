.filter-status-block {
  display: flex;
  flex-direction: row;
  margin: 0 0.5em;
}

.filter-status-block .filter-row {
  display: flex;
  flex-flow: row wrap;
}

.filter-status-block .filter-box {
  background: #eff3f6;
  border-radius: 5px;
  padding: 0 0.5em;
  line-height: 2.2em;
  margin: 0.25em;
  white-space: nowrap;
}

.filter-status-block .filter-box:first-child {
  margin-left: 0;
}

.filter-status-block .filter-box span.navigate:hover {
  text-decoration: underline;
}

.filter-status-block .filter-box i {
  margin-right: 0.5em;
  cursor: pointer;
  color: #797979;
}

.filter-status-block .filter-box i:hover {
  color: #000;
}

.filter-status-block .filter-controls span.navigate {
  color: #07c;
  line-height: 2.7em;
  font-weight: 600;
  margin: 0.25em;
  cursor: pointer;
  border: 1px solid #c2d1e0;
  padding: 0.25rem 0.75rem;
  border-radius: 3px;
}

.filter-status-block .filter-controls span.navigate:hover {
  text-decoration: underline;
}

.filter-controls {
  margin-left: 0.5rem;
}
