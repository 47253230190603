.modal-open .core-devices_list_filters.modal {
  overflow-y: hidden; /* This prevents the modal from being able to scroll off the screen due to long filter tables */
}

.core-devices_list_filters .fa-exclamation-triangle.enhanced-warning-icon {
  margin-top: 3px;
  line-height: 0.7em;
}

.core-devices_list_filters .modal-header {
  border-bottom: none;
}

.core-devices_list_filters .modal-body {
  height: auto;
  padding: 0;
}

.core-devices_list_filters .nav-tabs {
  padding-left: 1.875rem;
}

.core-devices_list_filters .nav-title {
  display: none;
}

.core-devices_list_filters .tab-content {
  padding: 15px 30px;
}

.core-devices_list_filters .filter-selection .filter-count {
  position: absolute;
  margin-top: 1.24rem;
  left: 1.875rem;
}

.core-devices_list_filters .filter-cell--additional.filter-cell--id-alertLevel {
  padding-left: 2rem;
}
