.rdtOpen .rdtPicker {
  right: -37px;
  color: black;
  margin-top: 5px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d7e2eb;
}

.chip-date-picker-container  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.75rem;
  background-color: transparent;
}

button.core-performance_calendar-reset {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.70rem;
  color: inherit;
  padding: 0;
  margin: 0;
  line-height: 1.75rem;

  &:focus {
    color: inherit;
    outline: none;
    box-shadow: none;
  }
}

button.core-performance_left-chevron-button,
button.core-performance_right-chevron-button {
  padding: 0 10px;
  justify-content: flex-start;
  background: transparent;
  border: none;
  cursor: pointer;
  color: inherit;

  &:focus {
    color:inherit;
    outline: none;
    box-shadow: none;
  }
}

.chip-active .core-performance_calendar-reset, .core-performance_calendar-reset:focus-within {
  color: #07c;
  outline: none; 
  box-shadow: none;
}

.chip-active .core-performance_left-chevron-button,
.chip-active .core-performance_right-chevron-button,
.core-performance_left-chevron-button:focus-within,
.core-performance_right-chevron-button:focus-within {
  color: #07c;
  outline: none; 
  box-shadow: none;
}

.chip-date-picker-container .fa-chevron-right,
.chip-date-picker-container .fa-chevron-left {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  margin: 0 !important;
  font-size: small;
}

.chip-date-picker-container .form-control {
  border: none;
  background: transparent;
  color: inherit;
  font-size: 0.875rem;
  text-align: center;
  padding: 0 0 0 5px;
  box-shadow: none;
  outline: none;
  width: 100px;
  flex: 1;
  cursor: pointer;
}

.chip-active .chip-date-picker-container .form-control {
  font-weight: 600;
  outline: none;
}

.core-performance-container-divider {
  border-right: 1px solid #333;
  height: 65%;
  margin: 0;
}
