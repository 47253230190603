.warrantyStatus {
  margin-top: 20px;
  font-weight: bold;
}

.batteryDetails_container table {
  width: 100%;
}

.batteryDetails_container {
  display: table;
  width: 100%;
}

.batteryDetails_container h1 {
  font-size: 1.1em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1em;
}

@media (width >= 977px) {
  .card-container {
    height: 100%;
  }
}
