.info-popover-content {
  max-width: 500px;
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-normal);
  text-align: left;
}

.info-popover-icon {
  color: #5a82a8;

  /*
  Left and right margin so the popover arrow is visually centered on the icon.
  Em unit so that spacing is proportional to icon size.
  */
  margin: 0 0.5em;
}
