.core_device_processes_composition .inner_card {
  position: relative;
}

.core_device_processes_composition .noSelectionOverlay {
  padding: 0;
}

.core_device_processes_composition .noSelectionOverlayInner {
  height: 100%;
  background-color: #f3f7fb;
}

.core-device_process-30-day-process-usage {
  margin: 0;
  flex: auto;
}

.core-device_process-30-day-process-usage .chart-and-legend-container {
  display: inline;
  display: flex;
}

.device_processes.table_part tr:hover {
  background: #e7f2fc !important;
  color: #1f71a8;
}

.device_processes tr:first-child:hover {
  color: #333;
}

.process-usage-chart .process-usage-chart-close {
  position: relative;
  z-index: 1000;
  margin-right: -1.1rem;
  margin-top: -0.75rem;
  border: 1px solid #d7e2eb;
}

.process-usage-chart .process-usage-chart-close:hover {
  border-color: transparent;
}

.core-device_process-30-day-process-usage .noSelectionOverlay {
  z-index: 999;
}
