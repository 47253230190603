.core-performance_alignment {
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 0.8rem;
  font-weight: 400;
}

.core-device_performance .performance-header {
  font-size: 1.286em;
  font-weight: 700;
  margin-left: 2.5rem;
  margin-top: 1.5rem;
}

.core-device_performance .performance-subHeader {
  font-weight: 400;
  margin-left: 2.5rem;
  margin-bottom: 1rem;
}

.core-device_performance .performance-record_subHeader {
  margin-top: 1rem;
}

.core-device_performance.core-device_content hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #d7e2eb;
}
