.pagination-bar-component {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0.5rem 1.875rem;
  box-shadow: 0 -1px 4px 0 #dadada;
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.pagination-bar-component__left,
.pagination-bar-component__right,
.pagination-bar-component__navigation,
.pagination-bar-component__message,
.pagination-bar-component__page-links,
.pagination-bar-component__go-to-page,
.pagination-bar-component__page-size {
  display: flex;
  align-items: center;
}

.pagination-bar-component__left,
.pagination-bar-component__right {
  align-items: start;
}

.pagination-bar-component__left {
  margin-right: 2em;
}

.pagination-bar-component__message {
  margin-right: 2em;
  flex: 0 1 auto;
  line-height: 28px;
}

.pagination-bar-component__page-size {
  line-height: 28px;
}

.pagination-bar-component__go-to-page {
  margin-right: 2em;
}

.pagination-bar-component__page-size__text,
.pagination-bar-component__go-to-page__text {
  margin-right: 10px;
  white-space: nowrap;
}

.pagination-bar-component__page-size__select {
  border: 1px solid #c2d1e0;
  border-radius: 3px;
  padding: 0.125rem;
}

.pagination-bar-component__page-links {
  min-width: 85px;
  justify-content: center;
}

.pagination-bar-component__page-link {
  display: flex;
  margin: 0 3px;
}

.pagination-bar-component__page-link__ellipsis {
  margin-right: 3px;
  color: #333;
}

.pagination-bar-component__page-link__number {
  color: #07c;
  cursor: pointer;
}

.pagination-bar-component__page-link__number:hover {
  color: #005eb3;
  text-decoration: underline;
}

.pagination-bar-component__page-link--active .pagination-bar-component__page-link__number,
.pagination-bar-component__page-link--active .pagination-bar-component__page-link__number:hover {
  color: #333;
  font-weight: 600;
  cursor: initial;
  text-decoration: underline;
}

.pagination-bar-component__navigation {
  justify-content: space-between;
}

.pagination-bar-component__navigate {
  background-color: #efefef;
  border: none;
  color: #979797;
  display: inline-block;
  font-size: 1rem;
  height: 28px;
  vertical-align: middle;
  text-decoration: none;
  width: 32px;
  line-height: 28px;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
}

.pagination-bar-component__navigate:hover {
  background-color: #e2e2e2;
  color: #545454;
  cursor: pointer;
}

.pagination-bar-component__navigate:disabled {
  margin-right: 8px;
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: default;
  box-shadow: none;
  height: 28px;
}

.pagination-bar-component__navigate--previous {
  border-radius: 5px 0 0 5px;
  margin-right: 10px;
}

.pagination-bar-component__navigate--next {
  border-radius: 0 5px 5px 0;
  margin-left: 10px;
}

@media (width <= 767px) {
  .pagination-bar-component {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pagination-bar-component__left,
  .pagination-bar-component__right {
    flex-basis: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pagination-bar-component__left {
    margin-bottom: 6px;
    margin-right: 0;
  }

  .pagination-bar-component__message {
    text-align: center;
    margin-right: 0;
  }

  .pagination-bar-component__go-to-page {
    margin-right: 0;
  }

  .pagination-bar-component__navigation {
    margin-top: 10px;
  }
}
