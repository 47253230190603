.pagination--container {
  background-color: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 5px;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.p-2 {
  margin-left: 20px;
  display: inline-block;
}

.button {
  background-color: #efefef;
  border: none;
  color: #979797;
  display: inline-block;
  font-size: 16px;
  height: 28px;
  vertical-align: middle;
  text-decoration: none;
  width: 32px;
  line-height: 28px;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
}

.prevBtn {
  border-radius: 5px 0 0 5px;
  margin-right: 10px;
}

.nextBtn {
  border-radius: 0 5px 5px 0;
  margin-left: 10px;
  margin-right: 20px;
}

.prevBtn:hover {
  background-color: #e2e2e2;
  color: #545454;
  cursor: pointer;
}

.nextBtn:hover {
  background-color: #e2e2e2;
  color: #545454;
  cursor: pointer;
}

.prevBtn:disabled {
  border-radius: 5px 0 0 5px;
  margin-right: 8px;
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: default;
  box-shadow: none;
  height: 28px;
}

.nextBtn:disabled {
  border-radius: 0 5px 5px 0;
  margin-left: 8px;
  margin-right: 15px;
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: default;
  box-shadow: none;
  height: 28px;
}

.pageText {
  line-height: 30px;
  vertical-align: middle;
}

.statusText {
  line-height: 30px;
  vertical-align: middle;
}
