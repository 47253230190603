.home-location-change_container .home-location-change_header {
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-semi-bold);
  padding: 10px;
}

.home-location-change_selection {
  font-size: var(--font-size-14px);
  font-weight: 400;
  padding: 10px;
  margin-left: 0.9rem;
  display: flex;
  align-items: center;
}

.home-location-change_container .from-home-location_selector {
  padding: 0 16px;
}

.home-location-change_container .to-home-location_selector {
  padding: 0 16px;
}

.selection-validation-error {
  color: #c03b3b;
  padding-left: 1rem;
}

.to-home-location_selector.wrong-home-location-selection .option-picker__control {
  border-color: #c03b3b;
}

.move-home-location__confirmation-modal .modal-content .modal-body {
  height: auto;
}

.move-home-location__confirmation-modal .modal-header,
.move-home-location__progress-modal .modal-header {
  border-bottom: 0 none;
}

.move-home-location__confirmation-modal .modal-footer,
.move-home-location__progress-modal .modal-footer {
  border-top: 0 none;
}

.move-home-location__confirmation-modal .modal-content,
.move-home-location__progress-modal .modal-content {
  max-width: 500px;
  margin: 0 auto;
}

.move-home-location__progress-modal .modal-content .modal-body {
  height: 6em;
}

.moving__status {
  text-align: center;
}
