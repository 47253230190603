.rectangle-label {
  font-size: 0.722em;
  border-radius: 5px;
  display: inline-block;
  padding: 0.2em 0.833em;
  font-weight: normal;
}

.rectangle-label.rectangle-label--green {
  background-color: #d5f2de;
  color: #22773b;
  border-bottom: 1px solid #92c7a4;
}

.rectangle-label.rectangle-label--blue {
  background-color: #d6e8f2;
  color: #3c669a;
  border-bottom: 1px solid #95bcd1;
}
