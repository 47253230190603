.details-card.battery .details-tab-header .selection-header {
  font-weight: 700;
  font-size: 1.125rem;
}

.details-card.battery .details-tab-header {
  margin-top: 0.5rem;
  padding-left: 1.45rem;
  margin-bottom: 0.5rem;
}

.details-card.battery .details-tab-header .rounded-label {
  margin-left: 1rem;
  top: -1px;
  position: relative;
}

.details-card.battery .details_row > div {
  margin-bottom: 1.1em;
}

.details-card.battery .mini-header {
  margin-bottom: 1.25rem;
  background-color: #f3f7fb;
  border: 1px solid #d7e2eb;
  padding: 0.25rem;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.details-card.battery .mini-header i {
  margin-right: 0.5em;
  color: #4a76a0;
}

.details-card.battery .details_right {
  padding-left: 1.2em;
  padding-right: 1.65em;
}

.details-card.battery .details_information button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (width <= 767px) {
  .details-card.battery .mini-header {
    text-align: right;
  }
}

.details-card.battery .details_information {
  padding: 1rem;
}

.details-card.battery .details_chargeLevel {
  padding: 2em 1em 1.45em;
}

.details-card.battery .details_chargeLevel .slider-title {
  display: inline-block;
  margin-bottom: 0.25em;
  font-weight: 600;
}

.details-card.battery .details_chargeLevel .chart-title {
  text-align: center;
  font-weight: 600;
  padding-bottom: 0.5em;
}

.details-card.battery .details_chargeLevel .chart-footer {
  text-align: center;
  font-size: 0.8em;
  margin-top: 0.8em;
}

.details-card.battery .details_chargeLevel .chart-column {
  display: flex;
  flex-direction: column;
  max-height: 400px;
}

.details-card.battery .details_chargeLevel .chart-column .linechart {
  flex-grow: 1;
}
