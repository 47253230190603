/* Base styles for old device essentials details card, still used by battery essentials battery/device cards */

.details-card .tab-panel .nav-tabs {
  margin-top: 0.5rem;
}

.details-card .details-tab-header {
  margin-top: 0.5rem;
  padding-left: 1.45rem;
  margin-bottom: 0.5rem;
}

.details-card.device .details-tab-header .selection-header {
  font-weight: 600;
  font-size: 1.1em;
}

.details-card .tab-panel .nav-item:nth-last-child(1) {
  margin-right: 0.2rem;
}

.details-card .details_left {
  padding-right: 0;
  padding-left: 1.438rem;
  font-weight: 600;
  max-width: 45%;
}

.details-card .details_right {
  padding-left: 0;
  padding-right: 1.2em;
}

@media (width <= 767px) {
  .details-card .details_left {
    text-align: right;
    padding-right: 0.5em;
  }

  .details-card .details_right {
    text-align: left;
    padding-left: 0.5em;
  }

  .details-card .details_information {
    padding-top: 1em;
  }
}

@media (width >= 768px) {
  .details-card .nav-rtl {
    margin-left: auto;
  }

  .details-card .details_information > [class*='col-']::before {
    background: #d7e2eb;
    bottom: 0;
    left: 0;
    top: 0;
    content: ' ';
    position: absolute;
    width: 1px;
  }

  .details-card .details_information > [class*='col-']:first-child::before {
    display: none;
  }

  .details-card .details_left {
    text-align: left;
  }

  .details-card .details_right {
    text-align: right;
  }
}

.details-card {
  height: 100%;
}

.details-card .card {
  height: 100%;
}

.details-card .row.gutters {
  padding: 0;
}

@media (width >= 1200px) {
  .details-card {
    height: 100%;
  }

  .details-card .card {
    height: 100%;
  }
}
