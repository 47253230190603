.core-device-coverage_header {
  padding: 1.8em 2.86em;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2em;
  z-index: 1001;
}

.core-device-coverage_headings {
  margin: 0;
  flex: 1 1 300px;
}

.core-device-coverage h2 {
  margin-left: 0.625rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.core-device-coverage h3 {
  margin-left: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 0.825rem;
  font-weight: 500;
}


.core-device-coverage_headings h2 {
  margin-left: 0;
}

.core-device-coverage_headings h3 {
  margin-bottom: 0;
  margin-left: 0;
}

.core-device-coverage_filters {
  padding: 0;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  gap: 1.25rem;
}

.core-device-coverage_time-picker-container {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.core-device-coverage_radio-container {
  display: flex;
  gap: 0.25rem;
}
